import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GlobalSystemParametersNames } from '../../../../constants/GlobalSystemParametersNames';
import { formatValidInput, formatValidSelect, getScaleByCurrency } from '../../../../helpers/FormatHelper';
import { getLookUpTable } from '../../../../networking/Networking';
import { getNostroBankAccounts } from '../../../../networking/NetworkingBankAccounts';
import { getTradersWithBlotter } from '../../../../networking/NetworkingBlotters';
import { getTradingInformationByCustomerID, updateCustomerTradingInformation } from '../../../../networking/NetworkingCustomers';
import { getActiveBlotterByCustomerID } from '../../../../networking/NetworkingTrader';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import DynamicSelect from '../../../shared/DynamicSelect';
import LoadingBox from '../../../shared/LoadingBox';
import NumberInput from '../../../shared/NumberInput';
import NewCommissionDetailsScreen from './NewCommissionDetailsScreen';
import AppContext from '../../../../AppContext';

var style = getStyle();
let globalFunctions = {};
export default class TradingInformationScreen extends React.Component {
    static contextType = AppContext;
    state = {
        tradingInformation: {
            Trader: '',
            TraderID: '',
            CustomerID: '',
            DefaultDepositPercent: '',
            MaximumExposureRatio: '',
            MaximumExposureLimit: '',
            ExposureLimitType: '',
            DefaultIncomingNostroBankID: '',
            DefaultIncomingNostroBankName: '',
            DefaultOutgoingNostroBankID: '',
            DefaultOutgoingNostroBankName: '',
            AverageTradeSize: '',
            ConfirmationType: '',
            MonthlyTradingVolume: '',
            ConfirmationLimit: '',
            EmailConfirmations: false,
            FaxConfirmations: false,
            DealNotification: false,
            Email: '',
            SMSConfirmation: false,
            MobilePhone: '',
            DefaultSettlementInstrument: '',
            DefaultDeliveryMethod: '',
            CreditLimit: '',
            ManualRateLimit: '',
            SpotLimit: '',
            ForwardLimit: '',
            SpreadHistoryDays: '',
            OnlineDealAlert: '',
            SpotLimitWarningOrEnforce: '',
            ForwardLimitWarningOrEnforce: '',
            BankFixInterface: '',
            UpdatedBy: '',
            UpdateToken: ''
        },
        validations: {
            trader: true,
            depositPercentage: true,
            creditLimit: true,
            incomingBank: true,
            outgoingBank: true,
            listMissing: []
        },
        dropdowns: {
            traders: [],
            "Confirmation Type": [],
            "Incoming Instruments": [],
            "Settlement Delivery Method": [],
            "BankInterfaces": [],
            banks: [],
            warningOrEnforce: [{ name: "Warning", value: "Warning" }, { name: "Enforce", value: "Enforce" }]
        },
        lookUpTables: [
            "Confirmation Type",
            "Incoming Instruments",
            "Settlement Delivery Method",
            "BankInterfaces"
        ],
        deliveryMethodList: {},
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        showTradersAndCommissions: false,
        updateTradingInfo: false,
        baseCurrency : localStorage.getItem(GlobalSystemParametersNames.BaseCurrency),
        actualTrader: 0
    }

    componentDidMount = async() => {
        globalFunctions = this.context;
        var propsCustomer = this.props.data;
        var customerID = 0;
        if (propsCustomer.CustomerID != null) {
            customerID = propsCustomer.CustomerID;
            await this.updateActualTrader(customerID);

            this.setState({ loading: true }, () => {
                getTradingInformationByCustomerID(customerID).then(
                    (value) => {
                        if (value != null) {
                            this.setState({ tradingInformation: value, loading: false });
                        }
                    }
                );    
            })
        }
        /*getActiveTradersMin().then(
            (json) => {
                if (json != null && json.traders != null) {
                    getTraders().then( // List of traders without assigned Blotter
                        (json2) => {
                            if (json2.traders != null) {
                                const activeTradersWithBlotter = json.traders
                                    .filter(({ TraderID }) => !json2.traders.some((traderWithoutBlotter) => TraderID === traderWithoutBlotter.TraderId))
                                    .map(t => ({ ...t, name: t.Trader, value: t.TraderID }));
                                this.setState({ dropdowns: { ...this.state.dropdowns, traders: activeTradersWithBlotter } });
                            }
                        }
                    )
                }
            }
        );*/
        getTradersWithBlotter().then( // List of traders without assigned Blotter
            (json) => {
                if (json.traders != null) {
                    const activeTradersWithBlotter = json.traders
                        .map(t => ({ ...t, name: t.Trader, value: t.TraderID }));
                    this.setState({ dropdowns: { ...this.state.dropdowns, traders: activeTradersWithBlotter } });
                }
            }
        )
        
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                if (json != null) {
                    for (var i = 0; i < this.state.lookUpTables.length; i++) {
                        let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                        if (index > -1) {
                            var table = json.lookUpTables[index].lookUpTableDetails.map(l => ({ ...l, name: l.LookUpTableDetail, value: l.LookUpTableDetail }));
                            this.setState({ dropdowns: { ...this.state.dropdowns, [this.state.lookUpTables[i]]: table } });
                        }
                    }
                    this.loadDeliveryMethods();
                }
            }
        );
        getNostroBankAccounts(customerID).then(
            (json) => {
                if (json != null) {
                    json.banks = json.banks.map(b => ({ ...b, name: b.BankName, value: b.BankID }))
                    this.setState({ dropdowns: { ...this.state.dropdowns, banks: json.banks } });
                }
            }
        );
    }

    updateActualTrader = async(customerID) =>
    {
        await getActiveBlotterByCustomerID(customerID).then( // List of traders without assigned Blotter
            (json) => {
                if (json.TraderID != null) {
                    this.setState({actualTrader: json.TraderID});
                }
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.UpdateToken !== this.props.data.UpdateToken ||
            this.state.updateTradingInfo !== prevState.updateTradingInfo){
            const propsCustomer = this.props.data;
            let customerID = 0;
            if (propsCustomer.CustomerID != null) {
                customerID = propsCustomer.CustomerID;
                getTradingInformationByCustomerID(customerID).then(
                    (value) => {
                        if (value != null) {
                            this.setState({ tradingInformation: value });
                        }
                    }
                );
            }
        }
    }

    loadDeliveryMethods() {
        let instruments = this.state.dropdowns["Incoming Instruments"];
        if (instruments!==null && instruments!==undefined && instruments.length>0){
            var deliveryMethodList = {};
            for (var k = 0; k < instruments.length; k++) {
                var list = [];
                if (instruments[k].Field4 !== '') {
                    var methodsList = instruments[k].Field4.split('§');
                    for (var j = 0; j < methodsList.length; j++) {
                        var item = { value: methodsList[j], name: methodsList[j] };
                        list.push(item);
                    }
                }
                deliveryMethodList[instruments[k].value] = list;
            }
            this.setState({ deliveryMethodList: deliveryMethodList });
        }
    }

    validateRequiredFields() {
        var valid = true
        var trader = true
        var incomingBank = true
        var outgoingBank = true
        var depositPercentage = true
        var creditLimit = true

        console.log("----> " + JSON.stringify(this.state.dropdowns.traders.find(e =>
            e.TraderID === parseInt(this.state.tradingInformation.TraderID))))

        var listItems = []

        if (this.state.tradingInformation.TraderID === "select" ||
            this.state.dropdowns.traders.find(e =>
                String.toString(e.TraderID) === String.toString(this.state.tradingInformation.TraderID)) === undefined
        ) {
            valid = false;
            trader = false;
            listItems.push("Trader")
        }

        if (this.state.tradingInformation.DefaultDepositPercent === "select" ||
            this.state.tradingInformation.DefaultDepositPercent === '' ||
            this.state.tradingInformation.DefaultDepositPercent === undefined ||
            this.state.tradingInformation.DefaultDepositPercent < 0) {
            valid = false;
            depositPercentage = false;
            listItems.push("Default Deposit Percent")
        }

        if (this.state.tradingInformation.CreditLimit === undefined ||
            this.state.tradingInformation.CreditLimit === "" ||
            this.state.tradingInformation.CreditLimit < 0) {
            valid = false;
            creditLimit = false;
            listItems.push("Credit Limit")
        }

        this.setState({
            validations: {
                ...this.state.validations,
                trader: trader,
                incomingBank: incomingBank,
                outgoingBank: outgoingBank,
                depositPercentage: depositPercentage,
                creditLimit: creditLimit,
                listMissing: listItems
            }
        });

        if (!valid) {
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    handleUpdateTradingInformationClick(event) {
        event.preventDefault();
        globalFunctions.startLoading();
        if(this.state.actualTrader != this.state.tradingInformation.TraderID)
        {
            globalFunctions.finishLoading();
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: "Customer's trader has to be the same as the one in the current commission period.",
                loading: false
            });
        }
        else
        {
        if (this.validateRequiredFields()) {
            const model = {
                AverageTradeSize: this.state.tradingInformation.AverageTradeSize,
                BankFixInterface: this.state.tradingInformation.BankFixInterface,
                ConfirmationLimit: this.state.tradingInformation.ConfirmationLimit,
                ConfirmationType: this.state.tradingInformation.ConfirmationType,
                CreditLimit: this.state.tradingInformation.CreditLimit,
                CustomerID: this.state.tradingInformation.CustomerID,
                DealNotification: this.state.tradingInformation.DealNotification,
                DefaultDeliveryMethod: this.state.tradingInformation.DefaultDeliveryMethod,
                DefaultDepositPercent: this.state.tradingInformation.DefaultDepositPercent,
                DefaultIncomingNostroBankID: this.state.tradingInformation.DefaultIncomingNostroBankID,
                DefaultOutgoingNostroBankID: this.state.tradingInformation.DefaultOutgoingNostroBankID,
                DefaultSettlementInstrument: this.state.tradingInformation.DefaultSettlementInstrument,
                Email: this.state.tradingInformation.Email,
                EmailConfirmations: this.state.tradingInformation.EmailConfirmations,
                ExposureLimitType: this.state.tradingInformation.ExposureLimitType != null ? this.state.tradingInformation.ExposureLimitType : 'Ratio',
                FaxConfirmations: this.state.tradingInformation.FaxConfirmations,
                ForwardLimit: this.state.tradingInformation.ForwardLimit,
                ForwardLimitWarningOrEnforce: this.state.tradingInformation.ForwardLimitWarningOrEnforce,
                ManualRateLimit: this.state.tradingInformation.ManualRateLimit,
                MaximumExposureLimit: this.state.tradingInformation.MaximumExposureLimit,
                MaximumExposureRatio: this.state.tradingInformation.MaximumExposureRatio,
                MobilePhone: this.state.tradingInformation.MobilePhone,
                MonthlyTradingVolume: this.state.tradingInformation.MonthlyTradingVolume,
                SMSConfirmation: this.state.tradingInformation.SMSConfirmation,
                SpotLimit: this.state.tradingInformation.SpotLimit,
                SpotLimitWarningOrEnforce: this.state.tradingInformation.SpotLimitWarningOrEnforce,
                SpreadHistoryDays: this.state.tradingInformation.SpreadHistoryDays,
                TraderID: this.state.tradingInformation.TraderID,
                UpdatedBy: localStorage.getItem('UserID'),
                UpdateToken: this.state.tradingInformation.UpdateToken
            };
            
            this.setState({ loading: true });
            updateCustomerTradingInformation(model).then(
                (json) => {
                    if (json.httpStatusCode !== 200) {
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: json.httpErrorMessage,
                            loading: false
                        });
                    } else {
                        this.setState({
                            tradingInformation: {
                                ...this.state.tradingInformation, UpdateToken: json.updateToken
                            },
                            showAlert: true,
                            alertTitle: 'Success',
                            alertMessage: 'Trading Information successfully saved.',
                            loading: false
                        });
                        this.props.refreshCustomer();
                    }
                }
            )
        }
        globalFunctions.finishLoading();
    }
    }

    closeAlert() {
        this.setState({
            showAlert: false, alertMessage: '', alertTitle: ''
        });
    }

    handleGetValueTrader(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, TraderID: value.value, Trader: value.name },
                validations: { ...this.state.validations, trader: true }
            });
        }
    }

    handleGetValueIncomingNostroBank(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, DefaultIncomingNostroBankID: value.value, DefaultIncomingNostroBankName: value.name },
                validations: { ...this.state.validations, bank: true }
            });
        }
    }

    handleGetValueOutgoingNostroBank(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, DefaultOutgoingNostroBankID: value.value, DefaultOutgoingNostroBankName: value.name },
                validations: { ...this.state.validations, bank: true }
            });
        }
    }

    handleGetValueConfirmationType(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, ConfirmationType: value.value }
            });
        }
    }

    handleGetValueInterface(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, BankFixInterface: value.value === "select" ? "" : value.value }
            });
        }
    }

    handleGetValueSpotLimitWarningOrEnforce(value) {
        if (value !== undefined && value !== 'select' && value.value !== 'select') {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, SpotLimitWarningOrEnforce: value.value }
            });
        }
        else{
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, SpotLimitWarningOrEnforce: null }
            });
        }
    }

    handleGetValueForwardLimitWarningOrEnforce(value) {
        if (value !== undefined && value !== 'select' && value.value !== 'select') {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, ForwardLimitWarningOrEnforce: value.value }
            });
        }
        else{
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, ForwardLimitWarningOrEnforce: null }
            });
        }
    }

    handleGetValueDefaultSettlementInstrument(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, DefaultSettlementInstrument: value.value, DefaultDeliveryMethod: '' }
            });
        }
    }

    handleGetValueDefaultDeliveryMethod(value) {
        if (value !== undefined && value !== "select") {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, DefaultDeliveryMethod: value.value }
            });
        }
    }

    handleUpdateDepositPercentage(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, DefaultDepositPercent: event.target.value },
            validations: { ...this.state.validations, depositPercentage: true }
        });
    }

    handleUpdateMaximumExposureRatio(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, MaximumExposureRatio: event.target.value }
        });
    }

    handleUpdateExposureLimitType(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, ExposureLimitType: event.target.value }
        });
    }

    handleUpdateMaximumExposureLimit(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, MaximumExposureLimit: event.target.value }
        });
    }

    handleUpdateTradeAverageSize(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, AverageTradeSize: event.target.value }
        });
    }

    handleUpdateMonthlyAverageVolume(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, MonthlyTradingVolume: event.target.value }
        });
    }

    handleUpdateConfirmationLimit(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, ConfirmationLimit: event.target.value }
        });
    }

    handleUpdateEmailConfirmations(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, EmailConfirmations: event.target.checked, FaxConfirmations: false }
        });
    }

    handleUpdateFaxConfirmations(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, FaxConfirmations: event.target.checked, EmailConfirmations: false }
        });
    }

    handleUpdateDealNotification(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, DealNotification: event.target.checked }
        })
    }

    handleUpdateEmail(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, Email: event.target.value }
        });
    }

    /*handleUpdateSmsCellNumber(event){
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, MobilePhone: event.target.value }
        });
    }*/

    handleUpdateSmsCellNumber = (mobilePhone) => {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, MobilePhone: mobilePhone},
        });
    }

    handleUpdateSMSConfirmation(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, SMSConfirmation: event.target.checked }
        })
    }

    handleUpdateCreditLimit(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, CreditLimit: event.target.value },
            validations: { ...this.state.validations, creditLimit: true }
        });
    }

    handleUpdateManualRateLimit(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, ManualRateLimit: event.target.value }
        });
    }

    handleUpdateSpotLimit(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, SpotLimit: event.target.value }
        });
    }

    handleUpdateForwardLimit(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, ForwardLimit: event.target.value }
        });
    }

    handleUpdateDaysOfSpreadHistory(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, SpreadHistoryDays: event.target.value }
        });
    }

    handleUpdateOnlineDealAlert(event) {
        this.setState({
            tradingInformation: { ...this.state.tradingInformation, OnlineDealAlert: event.target.value }
        });
    }

    openTradersAndCommissions = (event) => {
        event.preventDefault();
        this.setState({
            showTradersAndCommissions: true
        });
    }

    closeTradersAndCommissions = async () => {

        await this.updateActualTrader(this.props.data.CustomerID);
        this.setState({
            showTradersAndCommissions: false
        },  () => {
         if(this.state.actualTrader > 0)
         {
            this.setState({
                tradingInformation: { ...this.state.tradingInformation, TraderID: this.state.actualTrader },
                validations: { ...this.state.validations, trader: true }
            });
         }
        });
    }

    updateTraderInfo = () => {
        this.setState({
            updateTradingInfo: !this.state.updateTradingInfo
        });
    }

    render() {        
        let list = this.state.tradingInformation;
        var deliveryMethods = [];
        if(list.DefaultSettlementInstrument !== undefined &&
            list.DefaultSettlementInstrument !== '' &&
            list.DefaultSettlementInstrument !== 'select' &&
            this.state.deliveryMethodList[list.DefaultSettlementInstrument] !== undefined){
            deliveryMethods = this.state.deliveryMethodList[list.DefaultSettlementInstrument];
        }       

        return (
            <div className="">
                <ul id="accordion-trading-information" uk-accordion="multiple: true">
                    <li className="uk-open">
                        <h5 className="uk-accordion-title">Trading Information</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="trading-information">
                                <form className="uk-form-horizontal">
                                    <div className="uk-grid">
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel} >Trader (*)</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect className={formatValidSelect(this.state.validations.trader)} objValue={{ value: this.state.tradingInformation.TraderID, name: this.state.tradingInformation.Trader }} getValue={this.handleGetValueTrader.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.traders} id="form-trader" disabled/>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked" style={{alignSelf:'flex-end'}}>
                                            <button className="uk-button uk-button-green" onClick={this.openTradersAndCommissions}>Change Trader</button>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label" style={style.boldLabel}>Margin Percentage (*)</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className={formatValidInput(this.state.validations.depositPercentage)} placeholder="0.00" id="depositPercentage" type="Currency" 
                                                scale={getScaleByCurrency(this.state.baseCurrency)}
                                                value={this.state.tradingInformation.DefaultDepositPercent} onChange={this.handleUpdateDepositPercentage.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-4">
                                            <label className="uk-form-label">Maximum Exposure Ratio</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="MaximumExposureRatio" type="Currency" 
                                                scale={getScaleByCurrency(this.state.baseCurrency)}
                                                value={this.state.tradingInformation.MaximumExposureRatio} onChange={this.handleUpdateMaximumExposureRatio.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-4 uk-margin" style={{ marginBottom: '0px', paddingLeft: '20px' }}>
                                            <div className="" style={{ paddingBottom: "5px", marginTop: '5px' }}>
                                                <input value="Ratio" className="uk-radio" type="radio" name="chkExposureLimitType" onChange={this.handleUpdateExposureLimitType.bind(this)} checked={this.state.tradingInformation.ExposureLimitType === null || "Ratio"} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked" style={{ visibility: "hidden" }}>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <input className="uk-input" type="text" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-4">
                                            <label className="uk-form-label">Maximum Exposure Limit</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="MaximumExposureLimit" type="Currency" 
                                                scale={getScaleByCurrency(this.state.baseCurrency)}
                                                value={this.state.tradingInformation.MaximumExposureLimit} onChange={this.handleUpdateMaximumExposureLimit.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-4 uk-margin" style={{ marginBottom: '0px', paddingLeft: '20px' }}>
                                            <div className="" style={{ paddingBottom: "5px", marginTop: '5px' }}>
                                                <input value="Amount" className="uk-radio" type="radio" name="chkExposureLimitType" onChange={this.handleUpdateExposureLimitType.bind(this)} checked={this.state.tradingInformation.ExposureLimitType === "Amount"} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Default Incoming Nostro Bank</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect className={formatValidSelect(this.state.validations.incomingBank)} objValue={{ value: this.state.tradingInformation.DefaultIncomingNostroBankID, name: this.state.tradingInformation.DefaultIncomingNostroBankName }} getValue={this.handleGetValueIncomingNostroBank.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-incomingBank" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Default Settlement Instrument</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect className="uk-select" objValue={{ value: this.state.tradingInformation.DefaultSettlementInstrument }} getValue={this.handleGetValueDefaultSettlementInstrument.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Incoming Instruments"]} id="form-incoming-instruments" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Default Outgoing Nostro Bank</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect className={formatValidSelect(this.state.validations.outgoingBank)} objValue={{ value: this.state.tradingInformation.DefaultOutgoingNostroBankID, name: this.state.tradingInformation.DefaultOutgoingNostroBankName }} getValue={this.handleGetValueOutgoingNostroBank.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.banks} id="form-outgoingBank" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Default Delivery Method</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect className="uk-select" objValue={{ value: this.state.tradingInformation.DefaultDeliveryMethod }} getValue={this.handleGetValueDefaultDeliveryMethod.bind(this)} placeholder="Select a Method..." data={deliveryMethods} id="form-settlement-delivery-method" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Trade Average Size</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="AverageTradeSize" type="Currency" 
                                                scale={getScaleByCurrency(this.state.baseCurrency)}
                                                value={this.state.tradingInformation.AverageTradeSize} onChange={this.handleUpdateTradeAverageSize.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Monthly Average Volume</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="MonthlyTradingVolume" type="Currency" 
                                                scale={getScaleByCurrency(this.state.baseCurrency)}
                                                value={this.state.tradingInformation.MonthlyTradingVolume} onChange={this.handleUpdateMonthlyAverageVolume.bind(this)} />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Confirmation Type</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <DynamicSelect className="uk-select" objValue={{ value: this.state.tradingInformation.ConfirmationType }} getValue={this.handleGetValueConfirmationType.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Confirmation Type"]} id="form-confirmation-type" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-2 uk-form-stacked">
                                            <label className="uk-form-label">Confirmation Limit</label>
                                            <div className="" style={{ paddingBottom: "5px" }}>
                                                <NumberInput className="uk-input" placeholder="0.00" id="ConfirmationLimit" type="Currency" 
                                                scale={getScaleByCurrency(this.state.baseCurrency)}
                                                value={this.state.tradingInformation.ConfirmationLimit} onChange={this.handleUpdateConfirmationLimit.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Confirmation Information</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="confirmation-information">
                                <div className="uk-grid">
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label"><input className="uk-checkbox" value={this.state.tradingInformation.DealNotification} checked={this.state.tradingInformation.DealNotification} onChange={this.handleUpdateDealNotification.bind(this)} type="checkbox" />Deal Notification</label>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked" style={{ visibility: "hidden" }}>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            <input className="uk-input" type="text" />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label">Confirmation Delivery</label>
                                        <label className="uk-margin-right"><input value="Email" className="uk-radio" type="radio" name="radioDeliveryConfo" onChange={this.handleUpdateEmailConfirmations.bind(this)} checked={this.state.tradingInformation.EmailConfirmations} /> Email</label>
                                        <label><input value="Fax" className="uk-radio" type="radio" name="radioDeliveryConfo" onChange={this.handleUpdateFaxConfirmations.bind(this)} checked={this.state.tradingInformation.FaxConfirmations} /> Fax</label>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label">Email</label>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            <input className="uk-input" type="text" placeholder="" value={this.state.tradingInformation.Email} onChange={this.handleUpdateEmail.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked" style={{ alignContent: "center" }}>
                                        <label className="uk-form-label" style={{ visibility: "hidden" }}> space</label>
                                        <label className="uk-form-label"><input className="uk-checkbox" value={this.state.tradingInformation.SMSConfirmation} checked={this.state.tradingInformation.SMSConfirmation} onChange={this.handleUpdateSMSConfirmation.bind(this)} type="checkbox" />SMS Confirmation</label>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label">SMS Cell Number</label>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            {/*<input className="uk-input" type="text" placeholder="" value={this.state.tradingInformation.MobilePhone} onChange={this.handleUpdateSmsCellNumber.bind(this)} />*/}
                                            <PhoneInput
                                                inputProps  = {{
                                                    name        : 'phone',
                                                    required    : true,
                                                    autoFocus   : true
                                                }}
                                                enableSearch        = {true}
                                                disableSearchIcon   = {true}
                                                containerStyle      = {{
                                                    height: 30
                                                }}
                                                inputClass  = {'uk-input'}
                                                inputStyle  = {{
                                                    height: 30,
                                                    borderRadius: 0,
                                                    width: '100%'
                                                }}
                                                buttonStyle = {{
                                                    borderColor: '#CACACA'
                                                }}
                                                placeholder = 'Enter a Mobile Number'
                                                autoFormat
                                                copyNumbersOnly     = {true}
                                                countryCodeEditable = {true}
                                                value               = {this.state.tradingInformation.MobilePhone}
                                                onChange            = {this.handleUpdateSmsCellNumber}
                                                preferredCountries  = {['gb']}
                                                searchPlaceholder   = 'Search a country'
                                                autocompleteSearch

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="">
                        <h5 className="uk-accordion-title">Limits and others</h5>
                        <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                            <div className="limits-others">
                                <div className="uk-grid">
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label">Credit Limit</label>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            <NumberInput className={formatValidSelect(this.state.validations.creditLimit)} placeholder="0.00" id="CreditLimit" type="Currency" 
                                            scale={getScaleByCurrency(this.state.baseCurrency)}
                                            value={this.state.tradingInformation.CreditLimit} onChange={this.handleUpdateCreditLimit.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label">Manual Rate Limit</label>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            <NumberInput className="uk-input" placeholder="0.00" id="ManualRateLimit" type="Currency" 
                                            scale={getScaleByCurrency(this.state.baseCurrency)}
                                            value={this.state.tradingInformation.ManualRateLimit} onChange={this.handleUpdateManualRateLimit.bind(this)} />
                                        </div>
                                    </div>

                                    <div className="uk-width-1-4">
                                        <label className="uk-form-label">Spot Limit</label>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            <NumberInput className="uk-input" placeholder="0.00" id="SpotLimit" type="Currency" 
                                            scale={getScaleByCurrency(this.state.baseCurrency)}
                                            value={this.state.tradingInformation.SpotLimit} onChange={this.handleUpdateSpotLimit.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-4">
                                        <div className="uk-margin" style={{ marginTop: 26, paddingBottom: "5px", textAlign: 'left' }}>
                                            <DynamicSelect className="uk-select" objValue={{ value: this.state.tradingInformation.SpotLimitWarningOrEnforce }} getValue={this.handleGetValueSpotLimitWarningOrEnforce.bind(this)} placeholder="Select an Item..." data={this.state.dropdowns.warningOrEnforce} id="form-spot-warning-enforce" />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-4">
                                        <label className="uk-form-label">Forward Limit</label>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            <NumberInput className="uk-input" placeholder="0.00" id="ForwardLimit" type="Currency" 
                                            scale={getScaleByCurrency(this.state.baseCurrency)}
                                            value={this.state.tradingInformation.ForwardLimit} onChange={this.handleUpdateForwardLimit.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-4">
                                        <div className="uk-margin" style={{ marginTop: 26, paddingBottom: "5px", textAlign: 'left' }}>
                                            <DynamicSelect className="uk-select" objValue={{ value: this.state.tradingInformation.ForwardLimitWarningOrEnforce }} getValue={this.handleGetValueForwardLimitWarningOrEnforce.bind(this)} placeholder="Select an Item..." data={this.state.dropdowns.warningOrEnforce} id="form-forward-warning-enforce" />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label">Interface</label>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            <DynamicSelect className="uk-select" objValue={{ value: this.state.tradingInformation.BankFixInterface }} getValue={this.handleGetValueInterface.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["BankInterfaces"]} id="form-bank-interfaces" />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label"># Days of Spread History</label>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            <NumberInput className="uk-input" placeholder="0" id="SpreadHistoryDays" type="Integer" value={this.state.tradingInformation.SpreadHistoryDays} onChange={this.handleUpdateDaysOfSpreadHistory.bind(this)} />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked" style={{ display: 'none' }}>
                                        <label className="uk-form-label">Online Deal Alert</label>
                                        <div className="" style={{ paddingBottom: "5px" }}>
                                            <input className="uk-input" type="text" placeholder="" value={this.state.tradingInformation.OnlineDealAlert} onChange={this.handleUpdateOnlineDealAlert.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div style={{paddingTop: 10}}>
                    <button className="uk-button uk-button-green" onClick={this.handleUpdateTradingInformationClick.bind(this)}>Save</button>
                </div>
                
                <NewCommissionDetailsScreen 
                    show                        = {this.state.showTradersAndCommissions}
                    closeModalTradersCommission = {this.closeTradersAndCommissions}
                    customerId                  = {this.props.data.CustomerID}
                    customer                    = {this.props.data}
                    updateTraderInfo            = {this.updateTraderInfo}
                />

                <AlertBox
                    open={this.state.showAlert}
                    onClose={this.closeAlert.bind(this)}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.closeAlert.bind(this)}
                    messageBoldList={this.state.validations.listMissing}
                />
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}