import React from 'react';
import GenericBoard from '../../shared/GenericBoard';
import { getStyle} from '../../../styles/styles';
import {BoardName} from '../../../constants/BoardNames';

var style = getStyle();

export default class SecondReviewScreen extends React.Component {

    state = {
        showTitle: true,
        selectedItem: null
    }
    componentDidMount()
    {
       
    }
  
    isShowTitle = (value) => {
        this.setState({ showTitle: value})
    }
    render(){
    
        return(
            <div>
                {this.state.showTitle && <h3 className="uk-heading-divider" style={style.titleStyle}>Second Review Boards</h3>}
                <GenericBoard board={BoardName.SecondReviewBoard} refreshOnBack={true}  mainTitle={"Second Review Boards"} isShowTitle={this.isShowTitle} selectSection beneficiaryBody/>

            </div>
        );
    }
}