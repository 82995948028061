import { Breadcrumb } from 'antd';
import React from 'react';
import AppContext from '../../../../AppContext';
import { GlobalSystemParametersNames } from '../../../../constants/GlobalSystemParametersNames';
import { addBeneficiary, getBeneficiariesByID, saveBeneficiaryInAFEX, sendBeneficiaryToInterface, saveBeneficiaryInOpenPayd } from '../../../../networking/NetworkingBeneficiaries';
import { getParametersByName } from '../../../../networking/NetworkingGlobalSystemParameters';
import { searchInGlobalWatchLists } from '../../../../networking/NetworkingGlobalWatchListSearch';
import AlertBox from '../../../shared/AlertBox';
import AttachmentsDialog from '../../../shared/Dialogs/AttachmentsDialog';
import LoadingBox from '../../../shared/LoadingBox';
import AddressForm from '../../addresses/AddressForm';
import AuditBeneficiaryScreen from '../auditEntity/AuditBeneficiaryScreen';
import BeneficiariesScreen from '../updateCustomer/BeneficiariesScreen';
import BeneficiaryAdditionalProperties from './BeneficiaryAdditionalProperties';
import BeneficiaryBankAccount from './BeneficiaryBankAccount';
import BeneficiaryCompliance from './BeneficiaryCompliance';
import BeneficiaryInformationForm from './BeneficiaryInformationForm';
import BeneficiaryTasks from './BeneficiaryTasks';
import { updateProcessStatus } from '../../../../networking/NetworkingCompliance';
import { getRolesByUsername } from '../../../../networking/NetworkingRoles';
import SecondReviewScreen from '../../../back_office/boards/SecondReviewScreen';

let globalFunctions = {};
export default class BeneficiaryMaintenanceScreen extends React.Component {
    static contextType = AppContext;
    state = {
        beneficiaryID: 0,
        updateToken: 0,
        isValidated: true,
        isValidatedAddress: true,
        beneInfoErrors: [],
        addressErrors: [],
        notFirstLoad: true,
        notFirstLoadBene: true,
        showAlert: false,
        showAlertError: false,
        alertTitle: '',
        alertMessage: '',
        updateBeneficiary: false,
        pressedButton: false,
        saveProperties: false,
        saveTasks: false,
        selectBene: {},
        viewHistory: false,
        isClearAdd: false,
        isClearBene: false,
        activeTab: "beneficiary-information",
        enableButtonSaveInAfex: false,
        enableButtonSaveInOpenPayd: false,
        //OBJ FILTERS
        objBeneInfo: {},
        objAddress: {},
        objBankAccount: {},

        //Attachments
        showAttachmentsDialog: false,
        isReadOnly: false,

        //WatchList
        showAlertSaveSuspicious: false,
        toggleReloadGlobalSearch: false,
        enableButtonSaveToInterface: false,

        //Compliance
        beneficiaryHandlingMethodBackend: '',

        beneBankAccounts: [],
        enableCompliance: false,
        disableEditCompliance : false,
        showDisableMessage: false,
        checkComplianeStatus: true,
        messageDisableBene:'',
        applicationName:'ForeignExchange',
        roles:[]
    }

    componentDidMount() {
        globalFunctions = this.context;
        console.log(this.props.selectedBene);
        this.setState({ selectBene: this.props.selectedBene })
        //debugger;
        if (this.props.selectedBene.Address != null) {
            var objAdd = this.props.selectedBene.Address[0];
            this.setState({ objAddress: objAdd });
        }
        if (this.props.selectedBene.BankAccountsInfo != null){
            this.setState({ beneBankAccounts: this.props.selectedBene.BankAccountsInfo});
        }

        if (this.props.readOnly !== undefined) {
            this.setState({
                isReadOnly: this.props.readOnly
            })
        }

        getRolesByUsername(this.state.applicationName, localStorage.getItem('UserName')).then(
            (json) => { 
                if(json)
                {
                this.setState({roles: json});}
            }
        )

        getParametersByName(GlobalSystemParametersNames.EnableButtonBeneficiarySaveInAfex).then(
            (response) => {
                if (response != null && response.parameters != null && response.parameters.length > 0) {
                    this.setState({ enableButtonSaveInAfex: response.parameters[0].Value });
                }
            }
        )
        getParametersByName(GlobalSystemParametersNames.EnableButtonBeneficiarySaveInInterface).then(
            (response) => {
                if (response != null && response.parameters != null && response.parameters.length > 0) {
                    this.setState({ enableButtonSaveToInterface: response.parameters[0].Value });
                }
            }
        )
        getParametersByName(GlobalSystemParametersNames.EnableButtonBeneficiarySaveInOpenPayd).then(
            (response) => {
                if (response != null && response.parameters != null && response.parameters.length > 0) {
                    this.setState({ enableButtonSaveInOpenPayd: response.parameters[0].Value });
                }
            }
        )
        getParametersByName(GlobalSystemParametersNames.BeneficiaryHandlingMethodBackend).then(
            (response) => {
                if (response != null && response.parameters != null && response.parameters.length > 0) {                    
                    this.setState({ beneficiaryHandlingMethodBackend: response.parameters[0].Value });

                }
            }
        )        
    }
    componentDidUpdate(prevProps) {
        if (this.props.selectedBene && prevProps.updateToken !== this.props.updateToken) {
            this.setState({ selectBene: this.props.selectedBene, updateToken: this.props.updateToken });
        }

        if (prevProps.hideDetail !== this.props.hideDetail && !this.props.hideDetail) {          
            this.setState({ viewHistory: false});
            if(this.props.removeBreadcrumb)
            {
                this.props.removeBreadcrumb();
            }
        }

        if(this.state.beneficiaryHandlingMethodBackend  && this.state.selectBene.BeneficiaryId > 0 && this.state.selectBene.Status !== '' && this.state.checkComplianeStatus)
        {
            this.checkEditCompliance();
        }
    }

    checkEditCompliance = async() =>{

        let user = localStorage.getItem('UserID').toString().toLowerCase();
        let onlyPending = (this.state.beneficiaryHandlingMethodBackend === 'Save as Pending');
        let isSecondReview = this.state.roles.find(r => r === 'SecondReview');
        
        if(onlyPending && this.state.selectBene.Status.toString().toLowerCase() === 'pending activation')
        {
            if((this.state.selectBene.AssignedTo && this.state.selectBene.AssignedTo.toString().toLowerCase() !== user )
            && (this.state.selectBene.ProcessStatus === 'In Progress'))
            {
                let message = 'This beneficiary is already assigned to ' + this.state.selectBene.AssignedToName;
                this.setState({disableEditCompliance: true, showDisableMessage: true, checkComplianeStatus: false, messageDisableBene: message});
            } 
            else
            {
                if(isSecondReview)
                {
                    if(this.state.selectBene.ProcessStatus === 'Sent for Second Review' || ((this.state.selectBene.AssignedTo && this.state.selectBene.AssignedTo.toString().toLowerCase() === user )
                    && (this.state.selectBene.ProcessStatus === 'In Progress')))
                    {
                        this.setState({disableEditCompliance: false, showDisableMessage: false, checkComplianeStatus: false, messageDisableBene: ''});
                    }
                    else
                    {
                        let message = 'This beneficiary is being reviewed';
                        this.setState({disableEditCompliance: true, showDisableMessage: true, checkComplianeStatus: false, messageDisableBene: message});
                    }
                }
                else
                {
                    this.setState({disableEditCompliance: false, showDisableMessage: false, checkComplianeStatus: false, messageDisableBene: ''});
                }
            }
        }
        else
        {
            this.setState({disableEditCompliance: false, showDisableMessage: false, checkComplianeStatus: false, messageDisableBene: ''});
        }
    
    }

    changePage(value) {
    }

    updatePage(beneID, updateToken, updateTokenAddress, newBene) {
        if (newBene) {
            this.setState({ showAlert: true });
        }
        var objBeneInfo = this.state.objBeneInfo
        var objAdd = this.state.objAddress
        getBeneficiariesByID(beneID, 1).then(
            (json) => {
                console.log(json.beneficiary);
                ////debugger
                objBeneInfo.beneficiaryID = beneID
                objBeneInfo.updateToken = updateToken
                objAdd.AddressID = json.beneficiary[0].Address[0].AddressID
                objAdd.UpdateToken = updateTokenAddress
                this.setState({ objBeneInfo: objBeneInfo, objAddress: objAdd })
            }
        );
    }

    handleBackLink() {
        this.props.updateFunctionOrigin();
        this.props.backLink();
    }

    handleClearFieldsClick() {
        this.setState({
            notFirstLoad: true,
            notFirstLoadBene: true,
            showAlert: false,
            showAlertError: false,
            alertTitle: '',
            alertMessage: '',
            updateBeneficiary: false,
            pressedButton: false,
            saveProperties: false,
            saveTasks: false,
            selectBene: null,
            viewHistory: false,
            objBeneInfo: {},
            objAddress: null,
            objBankAccount: {}
        }, () => this.props.isNewClearBene())
    }

    handleSaveBeneficiaryInAFEX = async () => {

        let intermediaryAccount = null;
        if (this.state.selectBene.BankAccountsInfo == null || this.state.selectBene.BankAccountsInfo.length === 0) {
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'The beneficiary must have a bank account set as default' });
            return;
        }
        let localAccount = this.state.selectBene.BankAccountsInfo.find(ba => ba.AccountType === 'Actual / Local' && ba.DefaultAccount);
        if (localAccount !== undefined) {
            intermediaryAccount = this.state.selectBene.BankAccountsInfo.find(ba => ba.AccountType === 'Intermediate' && ba.BankAccountParentID === localAccount.BankAccountId);
            if (intermediaryAccount === undefined) {
                intermediaryAccount = null;
            }
        } else {
            localAccount = null;
        }

        if (localAccount == null) {
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'The beneficiary must have a bank account set as default' });
        } else {
            const model = {
                Beneficiary: {
                    PersonID: this.state.selectBene.PersonId
                },
                LocalAccount: localAccount,
                IntermediateAccount: intermediaryAccount
            };
            this.context.startLoading();
            const json = await saveBeneficiaryInAFEX(model);
            this.context.finishLoading();
            if (json != null) {
                switch (Number(json.httpStatusCode)) {
                    case 200: {
                        this.setState({
                            showAlert: true, alertTitle: 'Success', alertMessage: 'AFEX Beneficiary successfully saved.', loading: false
                        });
                        break;
                    }
                    default: {
                        this.setState({
                            showAlert: true, alertMessage: json.Message, alertTitle: 'Error', loading: false
                        });
                        break;
                    }
                }
            }
        }

    }

    handleSendBeneficiaryInInterface = async () => {
        let UserID = localStorage.getItem('UserID');
        if (this.state.beneBankAccounts == null || this.state.beneBankAccounts.length === 0) {
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'The beneficiary must have a bank account set as default' });
            return;
        }
        //let localAccount = this.state.selectBene.BankAccountsInfo.find(ba => ba.AccountType === 'Actual / Local' && ba.DefaultAccount);
        let localAccount = this.state.beneBankAccounts.find(ba => ba.AccountType === 'Actual / Local' && ba.DefaultAccount);
        if (localAccount === undefined){
            localAccount = null;
        }
        if(this.state.objBeneInfo.status !== 'Authorized'){
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'The beneficiary has to be Authorized.' });
        } else {
            if(localAccount == null){
                this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'The beneficiary must have a bank account set as default' });
            } else {
                var objFilter = {
                    BeneficiaryId: this.state.objBeneInfo.beneficiaryID === '' ? 0 : this.state.objBeneInfo.beneficiaryID,
                    CustomerId: this.props.customerID,
                    BeneficiaryType: this.state.objBeneInfo.customerType,
                    BypassValidation: this.state.objBeneInfo.bypassValidation,
                    ContactEmail: this.state.objBeneInfo.contactEmail != null ? this.state.objBeneInfo.contactEmail : '',
                    ContactPhone: this.state.objBeneInfo.contactPhone != null ? this.state.objBeneInfo.contactPhone : '',
                    EmailIndicator: this.state.objBeneInfo.emailIndicator,
                    DetailsOfPayment: this.state.objBeneInfo.detailsPayments != null ? this.state.objBeneInfo.detailsPayments : '',
                    ExpiryByPassDate: this.state.objBeneInfo.expiryByPassDate != null ? this.state.objBeneInfo.expiryByPassDate : '',
                    IndustrySector: this.state.objBeneInfo.industrySector != null ? this.state.objBeneInfo.industrySector : '',
                    ReferencesOfPayment: this.state.objBeneInfo.paymentReference != null ? this.state.objBeneInfo.paymentReference : '',
                    ReceiveLimit: this.state.objBeneInfo.limit,
                    PreferredMethodOfPayment: this.state.objBeneInfo.preferredPaymentIns,
                    PreferredDeliveryMethod: this.state.objBeneInfo.preferredDeliveryMet,
                    PaymentFlow: this.state.objBeneInfo.paymentFlow,
                    Salutation: this.state.objBeneInfo.salutation !== null ? this.state.objBeneInfo.salutation : '',
                    Name: this.state.objBeneInfo.companyName,
                    ContactName: this.state.objBeneInfo.contactName,
                    FirstName: this.state.objBeneInfo.firstName !== null ? this.state.objBeneInfo.firstName : '',
                    LastName: this.state.objBeneInfo.lastName !== null ? this.state.objBeneInfo.lastName : '',
                    ShortName: this.state.objBeneInfo.shortName !== null ? this.state.objBeneInfo.shortName : '',
                    Email: '',
                    Phone: '',
                    PhoneExt: '',
                    OfficeFax: '',
                    MobilePhone: '',
                    UpdateToken: this.state.objBeneInfo.updateToken,
                    UserId: UserID,
                    CustomerRelationRisk: this.state.objBeneInfo.customerRelationRisk != null ? this.state.objBeneInfo.customerRelationRisk : 0,
        
                    Address: this.state.objAddress !== null ? {
                        ...this.state.objAddress,
                        Address2: this.state.objAddress.Address2 !== null ? this.state.objAddress.Address2 : '',
                        POBox: this.state.objAddress.POBox !== null ? this.state.objAddress.POBox : '',
                        SinceWhen: this.state.objAddress.SinceWhen !== null ? this.state.objAddress.SinceWhen : '',
                    } : {},
                    BankAccounts: localAccount
                }
                if (this.state.selectBene != null) {
                    if (this.state.selectBene.Address != null) {
                        objFilter.Address.AddressID = this.state.selectBene.Address[0] != null ? this.state.selectBene.Address[0].AddressID : (this.state.selectBene.Address?.AddressID ?? 0)
                    } else {
                        objFilter.Address.AddressId = 0
                    }
                } else {
                    objFilter.Address.AddressId = 0
                }
                objFilter.Address.UpdatedBy = UserID;
                
                this.context.startLoading();
                const json = await sendBeneficiaryToInterface(objFilter);
                this.context.finishLoading();
                if (json != null) {
                    switch (Number(json.httpStatusCode)) {
                        case 200: {
                            this.setState({
                                showAlert: true, alertTitle: 'Success', alertMessage: 'Beneficiary successfully saved in interface.', loading: false
                            });
                            break;
                        }
                        default: {
                            this.setState({
                                showAlert: true, alertMessage: json.Message, alertTitle: 'Error', loading: false
                            });
                            break;
                        }
                    }
                }
            }            
        }

    }

    
    handleSaveBeneficiaryInOpenPayd = async () => {
        
        const model = {
            Beneficiary: {
                PersonID: this.state.selectBene.PersonId
            }
        };
        this.context.startLoading();
        const json = await saveBeneficiaryInOpenPayd(model);
        this.context.finishLoading();
        if (json != null) {
            switch (Number(json.httpStatusCode)) {
                case 200: {
                    this.setState({
                        showAlert: true, alertTitle: 'Success', alertMessage: 'OpenPayd Beneficiary successfully saved.', loading: false
                    });
                    break;
                }
                default: {
                    this.setState({
                        showAlert: true, alertMessage: json.Message, alertTitle: 'Error', loading: false
                    });
                    break;
                }
            }
        }

    }

    returnButtonState() {
        this.setState({ pressedButton: false });
    }

    handleButtonSaveClick = async () => {
        globalFunctions.startLoading();
        if (this.state.isValidated && this.state.isValidatedAddress) {
            if(this.state.objBeneInfo.BankAccountsInfo != null){
                this.setState({ beneBankAccounts: this.state.objBeneInfo.BankAccountsInfo});
            }
            if (this.state.objBeneInfo.bypassValidation) {
                let saveAsPending = false;
                if(this.state.beneficiaryHandlingMethodBackend === 'Save as Pending'){
                    
                    saveAsPending = true
                        this.setState({ showAlertSaveSuspicious: false, selectBene: {...this.state.selectBene, status: 'Pending activation'} }); // objBeneInfo: {...this.state.objBeneInfo, status: 'Pending activation'},
                    
                }
                this.saveAll(false, saveAsPending);
            } else {
                this.setState({ loading: true });
                const textToSearch = this.state.objBeneInfo.customerType === "Personal" ?
                `${this.state.objBeneInfo.firstName} ${this.state.objBeneInfo.lastName}` : this.state.objBeneInfo.companyName;
                const globalListsResponse = await searchInGlobalWatchLists(textToSearch, 'Beneficiary', this.state.objBeneInfo.beneficiaryID); 
                this.setState({ loading: false });
                if (globalListsResponse != null && globalListsResponse.MatchedWords.length === 0 && globalListsResponse.SourceNames.length === 0) {
                    let saveAsPending = false;
                    if(this.state.beneficiaryHandlingMethodBackend === 'Save as Pending'){
                        
                            saveAsPending = true
                        this.setState({ showAlertSaveSuspicious: false, selectBene: {...this.state.selectBene, status: 'Pending activation'} }); //objBeneInfo: {...this.state.objBeneInfo, status: 'Pending activation'}
                        
                    }
                    this.saveAll(false, saveAsPending);
                } else {
                    this.setState({ showAlertSaveSuspicious: true, alertTitle: 'Warning', alertMessage: 'Found Beneficiary in Watch Lists. The Beneficiary will be saved with status "Suspicious"' });
                }
            }
        }
        else {
            if (this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoad: false
                });
            } else if (!this.state.isValidated && !this.state.isValidatedAddress) {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false,
                    notFirstLoad: false
                });
            } else {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please fill all mandatory fields',
                    alertTitle: 'Error',
                    notFirstLoadBene: false
                });
            }
        }
        globalFunctions.finishLoading();
    }

    showAlertAfterPropertiesValidation = () => {
        this.setState({ loading: false });
    }

    saveAll = async (isSuspicious, saveAsPending) => {
        let UserID = localStorage.getItem('UserID');
        let isFirstReview = this.state.roles.find(r => r === 'FirstReview');
        let isSecondReview = this.state.roles.find(r => r === 'SecondReview');

        let Status = isSuspicious ? "Suspicious" : (saveAsPending ? (isFirstReview || isSecondReview ? this.state.objBeneInfo.status :"Pending activation") : this.state.objBeneInfo.status);
        

        var objFilter = {
            BeneficiaryId: this.state.objBeneInfo.beneficiaryID === '' ? 0 : this.state.objBeneInfo.beneficiaryID,
            CustomerId: this.props.customerID,
            //StatusBeneficiary: this.state.objBeneInfo.status,
            StatusBeneficiary: Status,
            BeneficiaryType: this.state.objBeneInfo.customerType,
            BypassValidation: this.state.objBeneInfo.bypassValidation,
            ContactEmail: this.state.objBeneInfo.contactEmail != null ? this.state.objBeneInfo.contactEmail : '',
            ContactPhone: this.state.objBeneInfo.contactPhone != null ? this.state.objBeneInfo.contactPhone : '',
            EmailIndicator: this.state.objBeneInfo.emailIndicator,
            DetailsOfPayment: this.state.objBeneInfo.detailsPayments != null ? this.state.objBeneInfo.detailsPayments : '',
            ExpiryByPassDate: this.state.objBeneInfo.expiryByPassDate != null ? this.state.objBeneInfo.expiryByPassDate : '',
            IndustrySector: this.state.objBeneInfo.industrySector != null ? this.state.objBeneInfo.industrySector : '',
            ReferencesOfPayment: this.state.objBeneInfo.paymentReference != null ? this.state.objBeneInfo.paymentReference : '',
            ReceiveLimit: this.state.objBeneInfo.limit,
            PreferredMethodOfPayment: this.state.objBeneInfo.preferredPaymentIns,
            PreferredDeliveryMethod: this.state.objBeneInfo.preferredDeliveryMet,
            PaymentFlow: this.state.objBeneInfo.paymentFlow,
            Salutation: this.state.objBeneInfo.salutation !== null ? this.state.objBeneInfo.salutation : '',
            Name: this.state.objBeneInfo.companyName,
            ContactName: this.state.objBeneInfo.contactName,
            FirstName: this.state.objBeneInfo.firstName !== null ? this.state.objBeneInfo.firstName : '',
            LastName: this.state.objBeneInfo.lastName !== null ? this.state.objBeneInfo.lastName : '',
            ShortName: this.state.objBeneInfo.shortName !== null ? this.state.objBeneInfo.shortName : '',
            Email: '',
            Phone: '',
            PhoneExt: '',
            OfficeFax: '',
            MobilePhone: '',
            UpdateToken: this.state.objBeneInfo.updateToken,
            UserId: UserID,
            CustomerRelationRisk: this.state.objBeneInfo.customerRelationRisk != null ? this.state.objBeneInfo.customerRelationRisk : 0,

            Address: this.state.objAddress !== null ? {
                ...this.state.objAddress,
                Address2: this.state.objAddress.Address2 !== null ? this.state.objAddress.Address2 : '',
                POBox: this.state.objAddress.POBox !== null ? this.state.objAddress.POBox : '',
                SinceWhen: this.state.objAddress.SinceWhen !== null ? this.state.objAddress.SinceWhen : '',
            } : {},
            BankAccounts: null
        }
        if (this.state.selectBene != null) {
            if (this.state.selectBene.Address != null) {
                objFilter.Address.AddressID = this.state.selectBene.Address[0] != null ? this.state.selectBene.Address[0].AddressID : (this.state.selectBene.Address?.AddressID ?? 0)
            } else {
                objFilter.Address.AddressId = 0
            }
        } else {
            objFilter.Address.AddressId = 0
        }
        objFilter.Address.UpdateToken = this.state.objAddress != null ? this.state.objAddress.UpdateToken : undefined;
        objFilter.Address.UpdatedBy = UserID;
        objFilter.Address.PersonID = this.props.customerPersonID;
        this.setState({ selectBene: objFilter });
        if ((this.state.objBeneInfo.isEmailValid && this.state.objBeneInfo.contactEmail !== '') || (!this.state.objBeneInfo.isEmailValid && this.state.objBeneInfo.contactEmail === '')) {
            this.setState({ loading: true });
            addBeneficiary(objFilter).then(
                (json) => {
                    if (json.httpStatusCode !== 200 && json.httpStatusCode !== 409) {
                        this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to save the beneficiary. Please try again and complete the required fields(*).', loading: false });
                    } else if (json.httpStatusCode !== undefined && json.httpStatusCode === 409) {
                        this.setState({ showAlertError: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error', loading: false });
                    } else {
                        let newBene = true;
                        if (objFilter.BeneficiaryId !== 0) {
                            newBene = false;
                        }
                        this.setState({ selectBene: {...this.state.selectBene, status: Status, BeneficiaryId : json.beneficiaryId, UpdateToken : json.updateToken }});
                        
                        if(!this.state.disableEditCompliance &&  Status !== 'Pending activation')
                        {
                            updateProcessStatus(json.beneficiaryId, 'Beneficiary', 'Closed', localStorage.getItem('UserID')).then(
                                (jsonUpdate) => {
                                    if (newBene) {
                                        this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', alertMessage: 'Beneficiary successfully saved.', loading: false }, () => this.updatePage(json.beneficiaryId, json.updateToken, json.updateTokenAddress, true))
                                    } else {
                                        this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', alertMessage: 'Beneficiary successfully saved.' }, () => this.updatePage(json.beneficiaryId, json.updateToken, json.updateTokenAddress, false))
                                    }
                                }
                            )
                        }
                        else
                        {
                            if (newBene) {
                                this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', alertMessage: 'Beneficiary successfully saved.', loading: false }, () => this.updatePage(json.beneficiaryId, json.updateToken, json.updateTokenAddress, true))
                            } else {
                                this.setState({ saveProperties: true, saveTasks: true, showAlert: true, alertTitle: 'Success', alertMessage: 'Beneficiary successfully saved.' }, () => this.updatePage(json.beneficiaryId, json.updateToken, json.updateTokenAddress, false))
                            }
                        }
                        
                        
                    }
                }
            );
            this.setState({ pressedButton: true });
        } else {
            this.setState({ showAlertError: true, alertMessage: 'Your email is incorrect. Please try again.', alertTitle: 'Error', notFirstLoad: false, notFirstLoadBene: false });
        }
    }
    isValidated = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidated: true,
                notFirstLoadBene: true,
                beneInfoErrors: []
            });
        } else {
            this.setState({
                isValidated: false,
                beneInfoErrors: { tab: "Beneficiary Information", errors: validator.errors }
            });
        }
    }
    isValidatedAddress = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                notFirstLoad: true,
                addressErrors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                addressErrors: { tab: "Address", errors: validator.errors }
            });
        }
    }

    handleViewHistoryClick() {
        this.setState({ viewHistory: true, fromAudit: true });
        if(this.props.addBreadcrumb)
        {
            this.props.addBreadcrumb(<Breadcrumb.Item className="breadcrumb-item-bold">Audit Beneficiary</Breadcrumb.Item>, 'hideBeneficiaryDetail')
        }
    }

    handleReturnToBeneficiary() {
        this.setState({ viewHistory: false });
    }

    handleAttachmentsClick() {
        this.setState({
            showAttachmentsDialog: true
        });
    }

    handleResetAnswersClick() {

    }

    handleSendOAEmailClick() {

    }

    closeAlert() {
        this.setState({ showAlert: false });
        if(this.state.alertTitle.toLocaleLowerCase() === 'success')
        {
            if(this.props.updateFunctionOrigin)
            {
            this.props.updateFunctionOrigin();
            }
            if(this.props.backLink)
            {
            this.props.backLink();
            }
        }
    }
    closeAlertError() {
        this.setState({ showAlertError: false, alertMessage: '', alertTitle: '', notFirstLoadBene: true, notFirstLoad: true });
    }
    formatVisibilityNew(beneID) {
        if (beneID === '' || beneID === null) { return "visible"; }
        else { return "hidden"; }
    }
    formatVisibilityUpdate(beneID) {
        if (beneID === '' || beneID === null) { return "hidden"; }
        else { return "visible"; }
    }

    //OBJ FILTERS
    objFilterBeneInfo(objFilter) {
        this.setState({ objBeneInfo: objFilter });
    }

    objFilterBeneAddress(objFilterA) {
        this.setState({ objAddress: objFilterA });
    }

    objFilterBeneAddProperties(value) {
        this.setState({ saveProperties: value });
    }

    objFilterBeneAddTasks(value) {
        this.setState({ saveTasks: value });
    }

    onClickTab = (tabName) => {
        this.setState({ activeTab: tabName });
    }

    enableComplianceSave = (enable) =>{
        this.setState({enableCompliance : enable});
    }

    formatVisibilityButton() {        
        if (this.state.activeTab === "account-information") { return "hidden"; }
        else { return "visible"; }
        
    }

    onCloseAttachmentsDialog = () => {
        this.setState({
            showAttachmentsDialog: false,
        })
    }

    closeAlertSaveSuspicious = async () => {
        this.setState({ showAlertSaveSuspicious: false, objBeneInfo: {...this.state.objBeneInfo, status: 'Suspicious'}, selectBene: {...this.state.selectBene, status: 'Suspicious'} });
        await this.saveAll(true, false);
    }

    refreshBeneficiary = async () => {
        const json = await getBeneficiariesByID(this.props.beneID);
        if (json != null) {
            this.setState({ selectBene: json.beneficiary[0] })
            if (this.props.selectedBene.Address !== null) {
                var objAdd = this.props.selectedBene.Address[0];
                this.setState({ objAddress: objAdd });
            }    
            if (this.props.readOnly !== undefined) {
                this.setState({
                    isReadOnly: this.props.readOnly
                })
            }
        }
    };

    refreshContact = async () => {
        this.setState({ toggleReloadGlobalSearch: !this.state.toggleReloadGlobalSearch });
    };

    
    render() {
        var selectBene = {}
        selectBene.PersonID = this.props.selectedBene.PersonId
        selectBene.CustomerID = this.props.selectedBene.BeneficiaryId
        selectBene.DealStatus = this.props.selectedBene.DealStatus
        selectBene.BeneficiaryID = this.props.beneID;

        var section = (
            <h3 className="uk-heading-divider component-title" style={{ visibility: this.formatVisibilityUpdate(this.props.beneID) }} >
                <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                    <i className="fa fa-arrow-left back-arrow"></i>
                </button>
                Beneficiary - {this.props.selectedBene.Name} - {this.props.beneID}
            </h3>
        );
        if (this.formatVisibilityNew(this.props.beneID) === "visible") {
            section = (
                <h3 className="uk-heading-divider component-title" style={{ visibility: this.formatVisibilityNew(this.props.beneID) }} >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left back-arrow"></i>
                    </button>
                    New Beneficiary
                </h3>
            );
        }

        var beneTitle = '';

        let breadcrumbBeneficiaryItem

        if (this.props.mainTitle === 'Beneficiary Boards' && this.props.selectedBene !== undefined && this.props.selectedBene !== null && this.props.selectedBene.Name !== '') {
            breadcrumbBeneficiaryItem = this.props.customerName + ' - ' + this.props.customerID + ' > ' + this.props.selectedBene.Name + ' - ' + this.props.beneID
        }        

        if (this.props.mainTitle === 'Beneficiary Card View' && this.props.selectedBene !== undefined && this.props.selectedBene !== null && this.props.selectedBene.Name !== '') {
            breadcrumbBeneficiaryItem = this.props.customerName + ' - ' + this.props.customerID + ' > ' + this.props.selectedBene.Name + ' - ' + this.props.beneID
        }

        if (this.props.mainTitle === 'First Review Boards' && this.props.selectedBene !== undefined && this.props.selectedBene !== null && this.props.selectedBene.Name !== '') {
            breadcrumbBeneficiaryItem = this.props.customerName + ' - ' + this.props.customerID + ' > ' + this.props.selectedBene.Name + ' - ' + this.props.beneID
        }

        if (this.props.mainTitle === 'Second Review Boards' && this.props.selectedBene !== undefined && this.props.selectedBene !== null && this.props.selectedBene.Name !== '') {
            breadcrumbBeneficiaryItem = this.props.customerName + ' - ' + this.props.customerID + ' > ' + this.props.selectedBene.Name + ' - ' + this.props.beneID
        }

        let showBreadcrumbBeneficiaryItem = breadcrumbBeneficiaryItem !== undefined && breadcrumbBeneficiaryItem !== null

        if (this.props.mainTitle != null) {
            beneTitle = (
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink.bind(this)}>{this.props.mainTitle}</Breadcrumb.Item>
                        {showBreadcrumbBeneficiaryItem && <Breadcrumb.Item className = "breadcrumb-item-bold" > {breadcrumbBeneficiaryItem} </Breadcrumb.Item>}
                    </Breadcrumb>
                </div>);
        }
        if (this.state.updateBeneficiary) {
            return <BeneficiariesScreen id={this.props.customerID} data={this.props.customerName} />
        }
        if (this.state.viewHistory) {
            return <AuditBeneficiaryScreen backLink={this.handleReturnToBeneficiary.bind(this)} beneficiaryID={this.props.beneID} />
        }
        return (
            <fieldset disabled={this.state.isReadOnly}>
                <div>
                    {beneTitle}
                    <div>
                        {/*section*/}
                        <ul uk-tab="">
                            <li>
                                <a href="#" id="beneficiary-information" onClick={() => this.onClickTab('beneficiary-information')}>Beneficiary Information</a>
                            </li>
                            <li>
                                <a href="#" id="address-information" onClick={() => this.onClickTab('address-information')}>Address</a>
                            </li>
                            <li>
                                <a href="#" id="account-information" onClick={() => this.onClickTab('account-information')}>Bank Account Info</a>
                            </li>
                            <li>{/*class="uk-disabled"*/}
                                <a href="#" id="additional-properties" onClick={() => this.onClickTab('additional-properties')}>Additional Properties</a>
                            </li>
                            <li>
                                <a href="#" id="compliance" onClick={() => this.onClickTab('compliance')}>Compliance</a>
                            </li>
                            <li>
                                <a href="#" id="tasks-list" onClick={() => this.onClickTab('tasks-list')}>Tasks List</a>
                            </li>
                        </ul>
                        <ul className="uk-switcher uk-margin">
                            <li>
                                <BeneficiaryInformationForm enableComplianceSave={this.enableComplianceSave} disableEditCompliance={this.state.disableEditCompliance} clearFields={this.props.isClearBene} newOrUpdate={this.formatVisibilityNew(this.props.beneID)} isValid={this.isValidated.bind(this)} updateFunction={this.updatePage.bind(this)} selectedBene={this.state.selectBene} beneID={this.props.beneID} customerName={this.props.customerName} objFilterBeneInfo={this.objFilterBeneInfo.bind(this)} notFirstLoad={this.state.notFirstLoadBene} refreshContact={this.refreshContact} />
                            </li>
                            <li>
                                <AddressForm clearFields={this.props.isClearAdd} selectedAddress={this.state.objAddress} getAddressInfo={this.objFilterBeneAddress.bind(this)} isValid={this.isValidatedAddress.bind(this)} validations={this.state.notFirstLoad} isCustomer={false} />
                                {/*<BeneficiaryAddressForm updateFunction={this.updatePage.bind(this)} selectedBeneAddress={this.props.selectedBene.Address}  beneID={this.props.beneID} objFilterBeneAddress={this.objFilterBeneAddress.bind(this)}/>*/}
                            </li>
                            <li>
                                <BeneficiaryBankAccount data={this.props.selectedBene.PersonId} disableEditCompliance={this.state.disableEditCompliance} isBeneficiary customerID={this.props.customerID} updatePage={this.refreshBeneficiary} updateBankAccount={this.props.updateBeneficiaryBankAccountHandler} createBankAccount={this.props.createBeneficiaryBankAccountHandler} />
                            </li>
                            <li>
                                <BeneficiaryAdditionalProperties getSaveProperties={this.objFilterBeneAddProperties.bind(this)} beneficiaryID={this.props.beneID} saveProperties={this.state.saveProperties} showFinalAlert={this.showAlertAfterPropertiesValidation} />
                            </li>
                            <li>
                                <BeneficiaryCompliance customer={selectBene} reloadGlobalSearch={this.state.toggleReloadGlobalSearch} objBeneInfo={this.state.objBeneInfo} objFilterBeneInfo={this.objFilterBeneInfo.bind(this)} />
                            </li>
                            <li>
                                <BeneficiaryTasks getSaveTasks={this.objFilterBeneAddTasks.bind(this)} beneficiaryID={this.props.beneID} personID={this.state.selectBene.PersonId} saveTasks={this.state.saveTasks} />
                            </li>
                        </ul>
                    </div>

                    <div style={{ visibility: this.formatVisibilityButton() }}>
                        {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearFieldsClick.bind(this)} type="button">Clear Fields</button>*/}
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick} type="button" disabled={this.state.disableEditCompliance}>Save</button>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleViewHistoryClick.bind(this)} type="button">View History</button>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleAttachmentsClick.bind(this)} type="button">Attachments</button>
                        {this.state.enableButtonSaveInAfex && (<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSaveBeneficiaryInAFEX} type="button" disabled={this.state.disableEditCompliance}>Save in AFEX</button>)}
                        {this.state.enableButtonSaveToInterface && (<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendBeneficiaryInInterface} type="button" disabled={this.state.disableEditCompliance}>Send to Interface</button>)}
                        {this.state.enableButtonSaveInOpenPayd && (<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSaveBeneficiaryInOpenPayd} type="button" disabled={this.state.disableEditCompliance}>Save in OpenPayd</button>)}
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleBackLink.bind(this)} type="button">Exit</button>
                    </div>
                    <AlertBox
                        open={this.state.showAlert}
                        onClose={this.closeAlert.bind(this)}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        type="Ok"
                        okClick={this.closeAlert.bind(this)}
                    />
                    <AlertBox
                        open={this.state.showAlertError}
                        onClose={this.closeAlertError.bind(this)}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        messageBoldList={[this.state.beneInfoErrors, this.state.addressErrors]}
                        type="Ok"
                        okClick={this.closeAlertError.bind(this)}
                    />
                    <AlertBox
                        open={this.state.showAlertSaveSuspicious}
                        onClose={this.closeAlertSaveSuspicious}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage} type="Ok" okClick={this.closeAlertSaveSuspicious} />
                    <AttachmentsDialog
                        open={this.state.showAttachmentsDialog}
                        onClose={this.onCloseAttachmentsDialog}
                        entityID={this.props.beneID}
                        entityType='Beneficiary'
                    />
                    <AlertBox
                        open={this.state.showDisableMessage}
                        onClose={() => this.setState({showDisableMessage: false})}
                        title={'Pending Activation'}
                        message={this.state.messageDisableBene}
                        type="Ok"
                        okClick={() => this.setState({showDisableMessage: false})}
                    />
                    <LoadingBox loading={this.state.loading} />
                </div>
            </fieldset>
        );
    }
}