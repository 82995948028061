import React from 'react';

import {getAllRoles, getUsersInRole, deleteRole} from '../../../networking/NetworkingRoles';
import DynamicTable from '../../shared/DynamicTable';
import Paginator from '../../shared/Paginator';
import NewDynamicTable from '../../shared/NewDynamicTable';
import {getDefaultPagingNumberOfItems} from '../../../helpers/PackageJsonHelper'
import {getStyle} from '../../../styles/styles'
import UserForm from './UserForm';
import CreateUserForm from './CreateUserForm';
import AlertBox from '../../shared/AlertBox';
import { getAllUsers } from '../../../networking/NetworkingUsers';
import DynamicSelect from '../../shared/DynamicSelect';

import {Breadcrumb} from 'antd';

var style = getStyle();

const itemsPerPage = getDefaultPagingNumberOfItems();

export default class UserScreen extends React.Component {
    state = {
        roles: [],
        role:'',
        usersInRole: [],
        selectedRole: {},
        selectedUser: {},
        totalPages: 0,
        pages: 1,
        totalPagesUser:0,
        pagesUser:1,
        newSearch: false,
        isCreate:false,
        userToRole:'',
        //Delete Message
        readyToDeleteRole: {},
        alertTitle:'',
        alertMessage:'',
        showDeleteAlert: false,
        showDeleteAlert2: false,
        showSuccessDeleteAlert: false,
        showSuccessDeleteAlert2: false,
        isDisabled:'uk-disabled',
        //forTabs
        step:1,
        objUserForm:[],
        objUserCreate:[],
    }
    //For Tabs
    onClickStepTab(value){
        this.setState({step: value});
    }

    //Pricing Category Screen ==> default
    handleUserFormSelection(obj){
        this.setState({objUserForm: obj});
    }

    //Spot Pricing Details Screen
    handleUserCreateSelection(obj){
        this.setState({step: 2, objUserCreate: obj});
}
    componentDidMount(){
        this.updateRolesTable()
    }
    transformListRoles(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'name':list[i]['Role Name'],
                        'value': list[i]['Role Name']});
        }
        return helper;
    }
    transformList(collection){
        var helper = collection;
        for (var i = 0; i<helper.length; i++){
            if(helper[i].Islock){
                helper[i].Islock='Yes'
            }else{
                helper[i].Islock='No'
            }
        }
        return helper;
    }

    updateRolesTable(){
        getAllRoles("ForeignExchange",1,50).then(
            (value) => {
                var helper = this.transformListRoles(value.Roles);
                this.setState({roles: helper});
            }
        );
    }

    updateUsersFromRoleTable(obj){
        getUsersInRole('ForeignExchange',obj.value).then(
            (value)=>{
                this.setState({usersInRole: this.transformList(value)});
            }
        );
    }

    handleRoleRowClick = (obj) =>{
        console.log(obj);
        this.setState({selectedRole: obj});
        getUsersInRole('ForeignExchange',obj['Role Name']).then(
            (value)=>{
                if(value.length!==undefined){
                    var helper = []
                    for (var i = 0; i < value.length; i++) {
                        if(value[i]!==null){
                            helper.push(value[i])  
                        }               
                    }
                    var totalCount = value.length;
                    var pages = Math.ceil(totalCount/itemsPerPage);
                    console.log(pages);
                    this.setState({totalPagesUser: pages, newSearch: false,usersInRole: this.transformList(helper)})
                }else{
                    this.setState({usersInRole: []});
                }
            }
        );
    }

    handleDeleteRole=(obj)=>{
        console.group("Role handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteRole: obj, 
                        alertTitle: "Are you sure you want to delete this Role?",
                        alertMessage:"This action can not be undone."});
    }
    handleDeleteUser=(obj)=>{
        console.group("Role handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert2: true, readyToDeleteRole: obj, 
                        alertTitle: "Are you sure you want to delete this User?",
                        alertMessage:"This action can not be undone."});
    }

    clearData(){
        this.setState({customers: [], searchedCustomerName: ''});
    }
    isCreateClick = (value) =>{
        this.setState({isCreate: value, isDisabled:'uk-active'})
    }

    getAllRoles = async () =>{
        var json = await getAllRoles("ForeignExchange",0,0);
        var allRoles = this.transformListRoles(json.Roles);
        return allRoles;
    }

    getUsers = async () =>{
        var json = await getAllUsers('ForeignExchange');
        var allUsers = this.transformList(json);
        return allUsers;
    }

    getAllUsers = (list,pages) =>{
        this.setState({usersInRole: this.transformList(list), totalPagesUser: pages, newSearch: false})
    }
    getStep = (step) =>{
        this.setState({step:step})
    }
    selectedUser = (obj) =>{
        this.setState({selectedUser:obj})
    }
    handleSearchClick = () =>{      
        getAllUsers('ForeignExchange').then(
            (json)=>{
                console.log(json)
                var totalCount = json.length;
                var pages = Math.ceil(totalCount/itemsPerPage);
                this.setState({usersInRole: this.transformList(json), totalPagesUser: pages, newSearch: false})
            }
        );
    }
    handleCreateClick = () =>{
        this.isCreateClick(true);
    }
    closeDeleteAlert2(){
        this.setState({showDeleteAlert2: false, readyToDeleteRole: {}, alertTitle:'', alertMessage:''});
    }
    openDeleteAlert2(){
        this.setState({showDeleteAlert: true});
    }
    okSuccessAlert(){
        this.setState({showSuccessDeleteAlert: false});
    }
    okSuccessAlert2(){
        this.setState({showSuccessDeleteAlert2: false});
    }

    handleUpdateRoles = (value) =>{
        if(value.value!==undefined){
            this.updateUsersFromRoleTable(value)
            this.setState({role: value.value});
        }else{}
    }
    handleUpdateUserToRole(event){
        this.setState({userToRole: event.target.value});
    }
    render(){
        var stepScreen = [];

        var classNameStep1 = "";
        var classNameStep2 = "uk-disabled";

        var breadcrumb = [];


        switch(this.state.step){
            case 1:
                classNameStep1 = "uk-active"
                stepScreen.push(<UserForm getStep = {this.getStep} onUserFormSelected={this.handleUserFormSelection.bind(this)} selectedUser = {this.selectedUser} isCreateClick = {this.isCreateClick}/>);
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold" onClick={this.onClickStepTab.bind(this, 1)}>Admin Users</Breadcrumb.Item>
                );
                break;
            case 2:
                stepScreen.pop();
                classNameStep2 = "uk-active"
                stepScreen.push(<CreateUserForm getStep = {this.getStep} onUserCreateSelected={this.handleUserCreateSelection.bind(this)} blankUser = {this.selectedUser} selectedUser = {this.state.selectedUser} isCreate = {this.state.isCreate} isCreateClick = {this.isCreateClick}/>);
                breadcrumb.push(
                    <Breadcrumb.Item onClick={this.onClickStepTab.bind(this, 1)}><a className="breadcrumb-item">Admin Users</a></Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold" onClick={this.onClickStepTab.bind(this, 2)}>{this.state.selectedUser?.UserName ?? 'New user'}</Breadcrumb.Item>
                );
                break;
            default:
                classNameStep1 = "uk-active"
                stepScreen.push(<UserForm getStep = {this.getStep} onUserFormSelected={this.handleUserFormSelection.bind(this)} selectedUser = {this.selectedUser} isCreateClick = {this.isCreateClick}/>);
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold" onClick={this.onClickStepTab.bind(this, 1)}>Admin Users</Breadcrumb.Item>
                );
                break;
        }
        return(
            <div>
                <div className="">
                    <div className="breadcrumb-div">
                        <Breadcrumb separator=">">
                            {breadcrumb}
                        </Breadcrumb>
                    </div>
                    {/*<h3 className="uk-heading-divider" style={style.titleStyle}>Admin Users</h3>
                    <ul className="uk-tab">
                        <li className={classNameStep1}><a onClick={this.onClickStepTab.bind(this, 1)}>Users</a></li>
                        <li className={classNameStep2}><a onClick={this.onClickStepTab.bind(this, 2)}>User Details</a></li>
                    </ul>*/}
                    {stepScreen}
                </div>
            </div>
        );
    }
}