import React from 'react';
import { BoardName } from '../../constants/BoardNames';
import { getBeneficiariesByID } from '../../networking/NetworkingBeneficiaries';
import { submitACHPayments, processPayments, getACHBatches, getAutoCoverPartners, getCurrenciesByPartner,createInterfacePaymentForCoverBank } from '../../networking/NetworkingFunds';
import { ExecuteGQuery, GetBoardByName, GetCategoriesByGenericBoardID, GetToolItemsByGenericBoardID } from '../../networking/NetworkingGenericBoards';
import DynamicAccordion from './DynamicAccordion';
import LoadingBox from './LoadingBox';
import NewDynamicTable from './NewDynamicTable.old';
import { getScreen } from './ScreenHelper';
import TheCSVButton from './TheCSVButton';
import AlertBox from '../shared/AlertBox';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ExportDialog from './ExportDialog';
import DynamicSelect from './DynamicSelect';
import DatePicker from './DatePicker';
import {formatDate, formatToCurrencyWithScale} from '../../helpers/FormatHelper';
import BoardParameters from '../tools/BoardParameters';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }); 
export default class GenericBoard extends React.Component {
    constructor(props) {
        super(props);
        this.handleBackLink = this.handleBackLink.bind(this);
        this.getCategoryValue = this.getCategoryValue.bind(this);
        this.changePage = this.changePage.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    state = {
        listCategories: [],
        value: '',
        result: [],
        csvResult: [],
        newSearch: false,
        totalPages: 0,
        currentPage: 1,
        clickedRow: false,
        returningFromAnotherWindow: false,
        objID: '',
        action: '',
        openLink: 0,
        buttons: [],
        selectedRows: [],
        loading: false,
        //ACH
        showAlert: false,
        alertMessage: '',
        alertTitle: '',
        regularBoard: true,
        ids: [],
        showExportDialog: false,
        showModal       : false,
        modalTitle      : '',
        fullWidth       : true,
        maxWidth        : 'sm',
        typeOfFile: 0,
        groupId: 0,
        noOfPaySubmited: 0,
        exportType: '',
        showProcessButton: false,
        processDate: '',
        showPartnersPaymentsButton: false,
        partnerName: '',
        partnerCurrency: '',
        partnerPaymentDate: new Date(),
        Batches: [],
        Partners: [],
        Currencies: [],
        paramters_json: '',
        showParametersModal: false
    }

    componentDidMount() {
        this.setState({loading: true});
        var boardID = 0;
        GetBoardByName(this.props.board).then(
            (json) => {
                if (json !== undefined) {
                    boardID = json.ID;
                    var helperParent = [];

                    if (boardID !== 0) {
                        GetCategoriesByGenericBoardID(boardID).then(
                            (json) => {                             
                                for (var c = 0; c < json.boardCategories.length; c++) {
                                    var boardQueries = json.boardCategories[c].boardQueries;
                                    var childrens = [];

                                    for (var i = 0; i < boardQueries.length; i++) {
                                        boardQueries[i].value = boardQueries[i].Query
                                        boardQueries[i].name = boardQueries[i].Name
                                        boardQueries[i].Parameters = boardQueries[i].Parameters
                                        childrens.push(boardQueries[i]);
                                    }
                                    helperParent.push({ value: json.boardCategories[c]["Category ID"], name: json.boardCategories[c]["Category Name"], childrens });
                                }

                                this.setState({ listCategories: helperParent });
                            }
                        );

                        GetToolItemsByGenericBoardID(boardID).then(
                            (json) => {
                                if (boardID === 1) {
                                    this.setState({ buttons: [] });
                                } else {
                                    if (json !== undefined && json.toolbarActions !== undefined) {
                                        this.setState({ buttons: json.toolbarActions });
                                    }
                                }
                            }
                        )

                        
                    }
                    
                }
                
            }

        );
    
        if (this.props.board === 'ApprovalBoard') {
            let button = [{
                ActionName: "ShowOutgoingQueueInfo",
                Content: "Approval",
                GenericBoardID: 1,
                ID: 131,
                Tooltip: "Approval"
            },{
                ActionName: "ShowGenerateMT103",
                Content: "Generate MT103",
                GenericBoardID: 2,
                ID: 132,
                Tooltip: "Generate MT103"
            }
        ]
            this.setState({ buttons: button })
        }

        if (this.props.board === BoardName.SwiftPaymentsBoard) {
            this.setState({
                buttons: [{
                    ActionName: "SendToSWIFT",
                    Content: "Send To SWIFT",
                    Tooltip: "Send To SWIFT"
                }]
            })
        }

        this.setState({loading: false});
    }

    componentDidUpdate(prevProps) {
        if (this.props.isApproval != null && prevProps.isApproval !== this.props.isApproval && this.props.isApproval) {
            if (this.state.value !== '') {
                this.executeQuery(this.state.value, 1, '');
            }
        }
    }

    getCategoryValue(value) {
        let showParametersModal = false;
        let modalTitle = '';
        if(value.Parameters !== undefined && value.Parameters != null && value.Parameters !== ''){
            showParametersModal = true;
            modalTitle = 'Board parameters';
        }
        if (!this.state.returningFromAnotherWindow) {
            this.setState({ value: value.value , paramters_json: value.Parameters, showParametersModal: showParametersModal, modalTitle: modalTitle});
            this.setState({ currentPage: 1 });
            this.executeQuery(value.value, 1, '');
        } else {
            this.setState({ returningFromAnotherWindow: false , paramters_json: value.Parameters, showParametersModal: showParametersModal, modalTitle: modalTitle});
        }
    }

    handleRowClick =async (obj) => {      
        if (obj["Key"] !== undefined && obj["Action"] !== undefined) {
            var selectedRows = [];
            var helper = {};
            if (this.props.beneficiaryBody != null || obj["Action"] == 'EditBeneficiaryInfo') {
                await getBeneficiariesByID(obj.Key, 1).then(
                    (jsonResponse) => {
                        helper.Key = obj["Key"];
                        helper["Customer Name"] = obj["Customer Name"];
                        helper.Action = obj['Action'];
                        helper.selectedBene = jsonResponse.beneficiary[0];
                        helper.type = jsonResponse.beneficiary[0].BeneficiaryType;
                        helper.customerID = jsonResponse.beneficiary[0].CustomerId;
                        helper.customerPersonID = jsonResponse.beneficiary[0].PersonIdCustomer;
                        helper.customerName = jsonResponse.beneficiary[0].NameCustomer;
                        selectedRows.push(helper);
                    }
                );
            } else {
                selectedRows = this.state.selectedRows;
                selectedRows.push(obj);
            }
            let name = obj["Customer Name"]
            let keyId = obj["Key"]
            if(obj['Action'] == 'ShowOutgoingFundsScreen'){
                name = obj["Key"]
                //keyId = obj["OutgoingFundsKey"]
            }
            this.setState({ objID: keyId/*obj["Key"]*/, objName: name, clickedRow: true, action: obj['Action'], selectedRows: selectedRows }, () => this.props.isShowTitle(false));
        }
    }
    handleBackLink() {
        this.setState({
            action: '',
            clickedRow: false,
            objID: '',
            objName: '',
            returningFromAnotherWindow: true,
            selectedRows: []
        }, () => {
            this.props.isShowTitle(true)
            if(this.props.refreshOnBack)
            {
                this.handleRefreshClick();
            }
        });
    }

    executeQuery(query, page, status) {
        if (query !== undefined && query !== '') {
            this.setState({loading: true});
            let userID = localStorage.getItem('UserID');
            if (this.state.value.includes('ACHSubmitted')){
                this.setState({ regularBoard: true, showProcessButton: true, showPartnersPaymentsButton: false })
            } else {
                this.setState({ regularBoard: true, showProcessButton: false, showPartnersPaymentsButton: false })
            }
            if(status !==''){
                this.setState({ showParametersModal: false});
            }
            if (query.includes('Partners_OutgoingPayments')){
                this.getPartners();
                this.setState({ regularBoard: true, showProcessButton: false, showPartnersPaymentsButton: true })
            }
            ExecuteGQuery(query, userID, null, null, null, this.state.groupId, status, 0, 0, this.state.partnerName, this.state.partnerCurrency).then(
                (value) => {
                    if (value !== undefined && value.result !== undefined) {
                        this.setState({ result: value.result }, () =>
                        {
                            this.handleSelectClick(value.result[0],false,false);
                            
                            if(value.result !== undefined && value.result !== null && value.result.length > 0){
                                if(value.result.length > 1 && (value.result[1]['BoardType']!== undefined && value.result[1]['BoardType'] !== null && 
                                    (value.result[1]['BoardType'] === 'ACHPayment' ||
                                    value.result[1]['BoardType'] === 'SVBBeneficiary' ||
                                    value.result[1]['BoardType'] === 'SVBWire')))
                                {
                                    this.setState({ regularBoard: false});
                                }
                                else
                                {
                                    this.setState({ regularBoard: true});
                                }
                            }
                        });
                        let theOtherValue = { ...value };
                        let csvArray = JSON.parse(JSON.stringify(theOtherValue.result));


                        this.setState({ csvResult: csvArray });
                        var totalCount = value.totalCount;
                        var pages = Math.ceil(totalCount / 15);
                        this.setState({ totalPages: pages, newSearch: true , objID:'', loading: false});
                        if (this.props.onRefresh != null) {
                            this.props.onRefresh();
                            this.handleRefreshClick();
                        }
                    }
                    else {
                        this.setState({ result: [], csvResult: [], totalPages: 0, newSearch: true , objID:''
                        , loading: false});
                    }
                }
            );
        }
        else {
            this.setState({ result: [], csvResult: [], totalPages: 0, newSearch: false , objID:''});
        }

    }

    changePage = (value) => {
        this.setState({ 
            currentPage: value, 
            //selectedRows: [] 
        });
        //this.executeQuery(this.state.value, value);
    }

    handleRefreshClick = () => {
        if(this.state.value !== '')
        {
            this.setState({ currentPage: 1, selectedRows: [], typeOfFile: 0,
                groupId: 0, noOfPaySubmited: 0, exportType: '', ids: [] });
            if (this.props.onRefresh != null) {
                this.props.onRefresh();
            }
            else
            {
                this.executeQuery(this.state.value, 1, '');
            }
        }
    }

    handleToolbarClick(btn) {
        if (btn.ActionName !== undefined && (this.state.objID !== undefined || btn.Content.includes("Add"))) {
            this.setState({ clickedRow: true, action: btn.ActionName });
        }
        if (btn.ActionName === 'ShowOutgoingQueueInfo') {
            this.props.clickApproval();
        }
        if (btn.ActionName === 'ShowGenerateMT103') {
            this.props.clickGenerateMT103();
        }
        if (btn.ActionName === 'SendToSWIFT') {
            this.props.sendSwiftPayments();
        }
        if (btn.ActionName === 'AddDeposit') {
            this.props.isShowTitle(false);
        }
    }

    handleSelectClick = (obj, checked, all) => {
        if (obj["Key"] !== undefined) {
            let selectedRows = [];
            let id = '';

            let multiselect = false;
            if(this.props.multiselect !== undefined)
            {
                multiselect = this.props.multiselect;
            }
            if(this.state.result.length > 1 && this.state.result[1]['BoardType']!== undefined && this.state.result[1]['BoardType'] !== null)
            {
                multiselect = true;
            }

            if(multiselect)
            {
                selectedRows = this.state.selectedRows;
            }

            if(all === true)
            {
                selectedRows = [];
            }else
            {
                if (checked) {
                    selectedRows.push(obj);
                    id = obj["Key"];
                } else {
                    selectedRows = selectedRows.filter(item => item["Key"] !== obj["Key"]);
                }
            }

            /*if(checked)
            {
                selectedRows.push(obj);
                id = obj["Key"];
            }
            else
            {
                if(all === true) //Cuando se da uncheck a all se tiene q resetear el selectedRows para no poner un foreach en el NewDynamicTable
                {
                    selectedRows = []
                }else
                {
                    selectedRows = selectedRows.filter(item => item["Key"] !== obj["Key"]);
                }
            }*/
            
            if (this.props.board === 'ApprovalBoard') {
                this.props.approvalFunction(obj, checked, all, selectedRows)
            }
            if (this.props.board === BoardName.SwiftPaymentsBoard) {
                this.props.handleCheckboxClick(obj, checked, all, selectedRows)
            }
            if (!this.state.regularBoard)
            {
                this.handleSubmitCheckboxClick(obj, checked, all, selectedRows)
            }
            this.setState({ objID: id, selectedRows: selectedRows });
        }
        else
        {
            this.setState({ objID: '' });
        }
    }

    getAll = async () => {
        let userID = localStorage.getItem('UserID');
        const value = await ExecuteGQuery(this.state.value, userID, null, null, null, this.state.groupId, 0, 0, 0, '', '')
        return value.result;
    }

    handleExportClick = () => {
        if(this.state.result !== undefined && this.state.result !== null && this.state.result.length > 0){
            if(this.state.result.length > 1 && (this.state.result[1]['BoardType']!== undefined && this.state.result[1]['BoardType'] !== null && 
                (this.state.result[1]['BoardType'] === 'ACHPayment' ||
                this.state.result[1]['BoardType'] === 'SVBBeneficiary' ||
                this.state.result[1]['BoardType'] === 'SVBWire')))
            {
                let exportType = '';
                if(this.state.result[1]['BoardType'] === 'ACHPayment') exportType = 'Payment';
                else if(this.state.result[1]['BoardType'] === 'SVBBeneficiary') exportType = 'Beneficiary';
                else if(this.state.result[1]['BoardType'] === 'ACHOPayment') exportType = 'ACHOutgoingFund';
                else exportType = 'OutgoingFund'
                this.submitACHPayments(exportType);
                //this.setState({showExportDialog: true, showModal: true, modalTitle: 'Export Options'});
            }
        }
    }

    submitACHPayments = (exportType) => {
        let idsToSubmit = this.state.ids;
        
        if(idsToSubmit.length > 0){
            submitACHPayments(localStorage.getItem('UserID'), idsToSubmit, exportType).then(
                (json) => {
                    if (json != null){
                        switch (json.httpStatusCode){
                            case 200:
                                this.setState({ typeOfFile: json.typeOfFile, groupId: json.groupId, noOfPaySubmited: json.noOfPaySubmited, exportType: exportType, showExportDialog: true, showModal: true, modalTitle: 'Export Options'})
                                break;
                            default:
                                this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to approval. Please try again..' })
                                break;
                        }
                    }
                }
            )
        } else {
            this.setState({showAlert: true, alertTitle: 'Warning', alertMessage: 'No payment selected.'});            
        }
    }

    closeAlert = () =>
    {
        this.setState({showAlert: false, alertTitle: '', alertMessage: ''});
    }
    
    handleSubmitCheckboxClick = (obj, checked, all, listSelects) => {
        var listOfIds = this.state.ids;
        var key = obj['Key'];
        var ID = key.length > 5 ? key.substr(0, 5) : key;
        var selected = listSelects;

        if(all === true)
        {
            selected = [];
            listOfIds = [];
        }else
        {
            if (checked) 
            {
                selected.push(obj);
                listOfIds.push(parseInt(ID));
            }else
            {
                selected = selected.filter(item => item["Key"] !== obj["Key"]);
                listOfIds = listOfIds.filter(item => item !== parseInt(ID));
            }
        }

        this.setState({
            selectedRows: selected,
            //boardName   : obj['Action'],
            ids         : listOfIds
        }, () =>{});
    }

    closeModal = () =>
    {
        this.setState({ showModal: false, modalTitle: '', showParametersModal: false });
    }

    handleProcessClick = () => {
        
    }

    handlePartnerPaymentsClick = () => {

        let boardType = ''
        if (this.state.selectedRows.length > 0) {
            boardType = this.state.selectedRows[0].BoardType;
        }
        else {
            this.setState({
                showAlert: true,
                alertTitle: 'Error',
                alertMessage: 'Please select some payments'
            })
            return;
        }

        if (boardType === 'PartnersOutgoingPayments') {
            let amounts = this.state.selectedRows.map(x => x.PaymentAmountForCalc) || []
            let totalAmount = amounts.reduce((partialSum, a) => partialSum + a, 0);
            let currencyId = this.state.selectedRows[0].Currency
            let interfaceName = this.state.selectedRows[0].Name
            this.setState({
                showConfirmPaymentToPartner: true,
                confirmPaymentToPartnerTitle: 'Confirmation',
                confirmPaymentToPartnerMessage: 'Please confirm. Do you want to send '
                    + formatToCurrencyWithScale(totalAmount, currencyId) + ' ' + currencyId + ' to ' + interfaceName + '.?'
            })
        }
    }

    onChangePartnerPaymentDate = (date) => {        
        this.setState({ partnerPaymentDate: date });
    }

    confirmPaymentToPartner = () => {
        if (this.state.selectedRows.length > 0) {
            let interfaceName = this.state.selectedRows[0].Name
            let customerId = this.state.selectedRows[0]['Customer ID']
            let currencyId = this.state.selectedRows[0].Currency
            let userId = localStorage.getItem('UserID');
            let dealIds = this.state.selectedRows.map(x => x['Deal Header ID']) || []
            let amounts = this.state.selectedRows.map(x => x.PaymentAmountForCalc) || []
            let totalAmount = amounts.reduce((partialSum, a) => partialSum + a, 0);
            let processDate = this.state.partnerPaymentDate

            this.setState({
                showConfirmPaymentToPartner: false,
                confirmPaymentToPartnerTitle: '',
                confirmPaymentToPartnerMessage: '',
                loading: true
            }, () => {
                createInterfacePaymentForCoverBank(interfaceName, customerId, 0, userId, dealIds, currencyId, totalAmount, formatDate(processDate))
                    .then(json => {
                        if (json != null) {
                            console.log(json)
                            switch (json.httpStatusCode) {
                                case 200:
                                    let message = (json.result || '').split("\n").map(function (item, idx) {
                                        return (
                                            <span key={idx}>
                                                {item}
                                                <br />
                                            </span>
                                        )
                                    })
                                    this.setState({
                                        showAlert: true,
                                        alertTitle: 'Success',
                                        alertMessage: message,
                                        loading: false
                                    }, () => this.handleRefreshClick())
                                    break;
                                default:
                                    this.setState({
                                        showAlert: true,
                                        alertTitle: 'Error',
                                        alertMessage: json.errorMessage || json.interfaceErrorMessage
                                    })
                                    break;
                            }
                        }
                    })
                    .finally(x => {
                        this.setState({
                            loading: false
                        })
                    })
            })
        }
    }

    closeConfirmPaymentToPartner = () => {
        this.setState({
            showConfirmPaymentToPartner: false,
            confirmPaymentToPartnerTitle: '',
            confirmPaymentToPartnerMessage: ''
        })
    }

    handleGroupId = (obj) => { 
        if (obj.value !== undefined){
            this.setState({groupId: parseInt(obj.value)})
            this.executeQuery(this.state.value, 1, '');
        }
    }

    handleUpdateProcessDate(date){
        if(date!==undefined){
            this.setState({outgoing:{...this.state.outgoing, ValueDate: formatDate(date)}}, () => {
                this.getBatches(formatDate(date));
            });
        }else{
            this.setState({outgoing:{...this.state.outgoing, ValueDate: ''}});
        }        
    }
    
    handlePartner = (obj) => {
        if (obj.value !== undefined) {
            this.setState({ partnerName: obj.value }, () => this.handleRefreshClick())
            this.getCurrencies(obj.value);
        }
    }

    handleCurrency = (obj) => {
        if (obj.value !== undefined) {
            this.setState({ partnerCurrency: obj.value }, () => this.handleRefreshClick())
        }
    }

    getBatches = (processDate) => {
        getACHBatches(processDate).then((json) => {
            if (json !== undefined && json.batches !== undefined && json.batches.length > 0){
                var batchesList = json.batches;
                batchesList.forEach(element => {
                    element.value = element.groupid;
                    element.name = element.groupid;
                });
                this.setState({ Batches: batchesList});
            } else { this.setState({ Batches: []}); }
        })
    }

    getPartners = () => {
        getAutoCoverPartners().then((json) => {
            if (json !== undefined && json.partners !== undefined && json.partners.length > 0){
                var partnersList = json.partners;
                partnersList.forEach(element => {
                    element.value = element.id;
                    element.name = element.Description;
                });
                this.setState({ Partners: partnersList});
            } else { this.setState({ Partners: []}); }
        })
    }

    getCurrencies = (partnerId) => {
        getCurrenciesByPartner(partnerId).then((json) => {
            if (json !== undefined && json.currencies !== undefined && json.currencies.length > 0){
                var currenciesList = json.currencies;
                currenciesList.forEach(element => {
                    element.value = element.currency;
                    element.name = element.currency;
                });
                this.setState({ Currencies: currenciesList});
            } else { this.setState({ Currencies: []}); }
        })
    }

    onSearchClick = (parameters) => {
        let status = ''
        if(parameters !== undefined){
            for(var i=0; i< parameters.length; i++){
                if(parameters[i].name.toLowerCase() === 'status'){
                    status = parameters[i].value;
                }
            }
        }
        this.setState({showParametersModal: false});
        this.executeQuery(this.state.value, 1, status);
    }

    render() {
        //typecollapse="collapsible: false"
        var screen = [];
        var toolbarbuttons = [];
        var multiselect = false;
        var selectSection = null;
        var activeSelectAll = false;

        if(this.props.selectSection !== undefined)
        {
            selectSection = this.props.selectSection;
        }
        if(this.props.multiselect !== undefined)
        {
            multiselect = this.props.multiselect;
        }
        if(this.props.activeSelectAll !== undefined)
        {
            activeSelectAll = this.props.activeSelectAll;
        }
        if(this.state.result !== undefined && this.state.result !== null && this.state.result.length > 0){
            if(this.state.result.length > 1 && (this.state.result[1]['BoardType']!== undefined && this.state.result[1]['BoardType'] !== null && 
                (this.state.result[1]['BoardType'] === 'ACHPayment' ||
                this.state.result[1]['BoardType'] === 'SVBBeneficiary' ||
                this.state.result[1]['BoardType'] === 'SVBWire' ||
                this.state.result[1]['BoardType'] === 'PartnersOutgoingPayments'))){
                    multiselect = true;
                    selectSection = null;
                    activeSelectAll = true;
                }
        }

        if (this.state.result !== undefined && this.state.result !== null && this.state.result.length > 0 && this.state.result[0]['BoardType'] === 'PartnersOutgoingPayments') {
            multiselect = true;
            selectSection = null;
            activeSelectAll = true;
        }

        if(this.state.value.includes('ACHSubmitted')){
            multiselect = true;
            selectSection = null;
            activeSelectAll = true;
        }

        if (this.state.buttons !== undefined) {
            let i=0;
            let addPaymentButton = false;
            for (i = 0; i < this.state.buttons.length; i++) {
                addPaymentButton = this.state.buttons[i].Content === 'Add Payment'
                if (this.state.showPartnersPaymentsButton && addPaymentButton) {
                    continue;
                }
                toolbarbuttons.push(<button id={"btn_"+i} className="uk-button uk-button-green  uk-float-right" type="button" onClick={this.handleToolbarClick.bind(this, this.state.buttons[i])}>{this.state.buttons[i].Content}</button>);
            }
        }
        let processSection = []
        if(this.state.showProcessButton){
            processSection.push(<div className="uk-grid uk-margin">
                <div className="uk-width-1-6">
                    <label className="uk-form-label" >Process date</label>
                </div>
                <div className="uk-width-1-6">
                    <DatePicker 
                        className    = "uk-input"
                        value        = {this.state.processDate}                         
                        onDayChange  = {this.handleUpdateProcessDate.bind(this)}/>
                </div>
                <div className="uk-width-1-6">
                    <label className="uk-form-label" >Batch group</label>
                </div>
                <div className="uk-width-1-6">
                    <DynamicSelect 
                            objValue={{ value: this.state.groupId }} 
                            getValue={this.handleGroupId} 
                            placeHolder="Select a groupId..." 
                            data={this.state.Batches} 
                            id="form-group-id" />
                </div>
            </div>)
        }

        let partnersPaymentSection = []
        if (this.state.showPartnersPaymentsButton) {
            partnersPaymentSection.push(<div className="uk-grid uk-margin">
                <div className="uk-width-1-12">
                    <label className="uk-form-label" >Partner</label>
                </div>
                <div className="uk-width-1-4">
                    <DynamicSelect
                        objValue={{ value: this.state.partnerName }}
                        getValue={this.handlePartner}
                        placeHolder="Select a partner..."
                        data={this.state.Partners}
                        id="form-partner-name" />
                </div>
                <div className="uk-width-1-12">
                    <label className="uk-form-label" >Currency</label>
                </div>
                <div className="uk-width-1-4">
                    <DynamicSelect
                        objValue={{ value: this.state.partnerCurrency }}
                        getValue={this.handleCurrency}
                        placeHolder="Select a currency..."
                        data={this.state.Currencies}
                        id="form-partner-ccy" />
                </div>
                <div className="uk-width-1-12">
                    <label className="uk-form-label">Date</label>
                </div>
                <div className="uk-width-1-4">
                    <DatePicker useNew id="dp-partnerPayments" key="dp-partnerPayments" className="uk-input"
                        value={this.state.partnerPaymentDate} onDayChange={this.onChangePartnerPaymentDate}/>
                </div>
                <div className="uk-width-1-1" style={{ padding: '5px' }}><hr /></div>
            </div>)
        }
        
        var screenActionClickRow = getScreen(this.state.action, this.state.objID, this.handleBackLink, this.state.objName, null, this.state.selectedRows.length === 1 ? this.state.selectedRows[0] : null, this.props.mainTitle, this.props.canUpdateCustomerProcessStatus);
        if (this.state.clickedRow && screenActionClickRow !== undefined) {
            screen.push(screenActionClickRow);
            //screen.push(<CustomerScreen backLink={this.handleBackLink} data={this.state.objID} />);
        } else {
            screen.push(
                <div>
                    <div className="uk-grid">
                        <div className="uk-width-1-5"></div>
                        <div className="uk-width-4-5">
                            {partnersPaymentSection}
                        </div>
                        <div className="uk-width-5-5">
                            {processSection}                            
                            {this.state.regularBoard && <TheCSVButton board={this.props.board} data={this.state.csvResult} />}
                            {!this.state.regularBoard && !this.state.showPartnersPaymentsButton && <button className="uk-button uk-button-green uk-float-right" type="button" onClick={this.handleExportClick}>Export Payment</button>}
                            <button className="uk-button uk-button-green uk-float-right" type="button" id="btn-refresh" onClick={this.handleRefreshClick}>Refresh</button>
                            {this.state.showProcessButton && <button className="uk-button uk-button-green uk-float-right" type="button" id="btn-process" onClick={this.handleProcessClick}>Process</button>}
                            {this.state.showPartnersPaymentsButton && <button className="uk-button uk-button-green uk-float-right" type="button" id="btn-partner-payments" onClick={this.handlePartnerPaymentsClick}>Process</button>}
                            {toolbarbuttons}
                        </div>
                        <div className="uk-width-1-5">
                            <DynamicAccordion id="test-list" typecollapse="" openLink={this.state.openLink} selectFirst={true} classNameList="uk-list uk-list-striped" data={this.state.listCategories} getValue={this.getCategoryValue} objValue={{ value: this.state.value, Value: this.state.value }} />
                        </div>
                        <div className="uk-width-4-5">
                            {/*<BoardParameters value={this.state.paramters_json}/>*/}
                            <NewDynamicTable
                                hiddenHeaders={['Key', 'Action', 'OutgoingFundsKey', 'EntityType', 'BoardType', 'EXP', 'PaymentAmountForCalc']}
                                checkboxFunction={this.handleSelectClick}
                                useCheckBox={selectSection != null ? false : multiselect}//{this.props.selectSection != null ? false : multiselect}
                                radioButtonFunction={this.handleSelectClick}
                                useRadioButton={selectSection != null ? false : !multiselect}//{this.props.selectSection != null ? false : !multiselect}
                                data={this.state.result}
                                enableClick={true}
                                clickFunction={this.handleRowClick}
                                newSearch={this.state.newSearch}
                                selectedRows={this.state.selectedRows}

                                usePaginator={true}
                                changePage={this.changePage}
                                numberOfPages={this.state.totalPages}
                                pageNumber={this.state.currentPage}
                                numberPerPage={15}
                                enableSortByHeader={true}
                                useFilter={true}
                                filterFunction={this.getAll}
                                activeSelectAll={activeSelectAll}
                            />
                          </div>
                    </div>
                    <Dialog
                        open={this.state.showModal} 
                        onClose={this.closeModal}
                        fullWidth={this.state.fullWidth}
                        maxWidth={this.state.maxWidth}>
                        <DialogTitle id="customized-dialog-gwlatr-title" onClose={this.closeModal}>
                            {this.state.modalTitle}
                        </DialogTitle>
                        <DialogContent dividers>
                            {this.state.showExportDialog && <ExportDialog groupId={this.state.groupId} noOfPaySubmited={this.state.noOfPaySubmited} typeOfFile={this.state.typeOfFile} exportType={this.state.exportType} onRefresh={this.handleRefreshClick}/>}
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.showParametersModal} 
                        onClose={this.closeModal}
                        fullWidth={this.state.fullWidth}
                        maxWidth={this.state.maxWidth}>
                        <DialogTitle id="customized-dialog-gwlatr-title" onClose={this.closeModal}>
                            {this.state.modalTitle}
                        </DialogTitle>
                        <DialogContent dividers>
                            {this.state.showParametersModal && <BoardParameters value={this.state.paramters_json} onSearchClick={this.onSearchClick}/>}
                        </DialogContent>
                    </Dialog>
                    <AlertBox id="global-Alert"
                        type="Ok"
                        open={this.state.showAlert}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        okClick={this.closeAlert}
                        onClose={this.closeAlert} />
                    <AlertBox
                        open={this.state.showConfirmPaymentToPartner}
                        onClose={this.closeConfirmPaymentToPartner}
                        title={this.state.confirmPaymentToPartnerTitle}
                        message={this.state.confirmPaymentToPartnerMessage}
                        type="Yes/No"
                        yesClick={this.confirmPaymentToPartner}
                        noClick={this.closeConfirmPaymentToPartner} />
                </div>
            );
        }
        return (<div>{screen}
            <LoadingBox loading={this.state.loading} />
        </div>);
    }
}