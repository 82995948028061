import { Breadcrumb } from 'antd';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import AppContext from '../../../AppContext';
import AlertBox from '../../shared/AlertBox';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { SearchOutlined } from '@ant-design/icons';
import { getReferredOutgoingFunds, saveReferredDealComment } from '../../../networking/NetworkingCustomerLimitProfile';
import { EditOutlined } from '@material-ui/icons';
import ShowDynamicInfo from '../../shared/ShowDynamicInfo';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const NewOutgoingFundsForm = () => {
    const context = useRef(useContext(AppContext));
    const [deals, setDeals] = useState([]);
    const [dealDetail, setDealDetail] = useState(null);
    const [outgoingID, setOutgoingID] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showModalComment, setShowModalComment] = useState(false);

    const [reloadDeals, setReloadDeals] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [comment, setComment] = useState('');


    const [columnDetail, setColumnDetail] = useState(null);

    useEffect(() => {
        loadDeals();
    }, [reloadDeals]);

    const loadDeals = async () => {
        const json = await getReferredOutgoingFunds(0,0);
        if (json != null && json.referreadDeals != null) {
            const _deals = json.referreadDeals.map((e, index) => ({ ...e, key: `Deals-${index}` }));
            setDeals(_deals);
            if (json.columnDetail != null) {
                const _columnDetail = JSON.parse(json.columnDetail);
                setColumnDetail(_columnDetail);
            }
        } else {
            setDeals([]);
        }
    };

    const save = async () => {
        try {
            let userID = localStorage.getItem('UserID');
        var model = {
            customerProfileDealViolationId: dealDetail.ID,
            comment: comment,
            userId: userID
        }
        if(comment){
            saveReferredDealComment(model).then((json)=>
            {
                if (json !== undefined) {
                    if (json.httpStatusCode !== 200) {
                        onCloseDetail();
                        setAlertTitle('Error');
                        setAlertMessage(json.httpErrorMessage);
                        setShowAlert(true);
                    }
                  else {
                    onCloseDetail();
                    setAlertTitle('Success');
                    setAlertMessage('Comment successfully saved.');
                    setShowAlert(true);
                    setReloadDeals(!reloadDeals);
                  }
                }
            })
        }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const onRowSelected = async (record) => {
        setDealDetail(record);
        setShowModal(true);        
        setShowModalComment(false);        
    };

    const onAddComment = async (record) => {
        setDealDetail(record);
        setOutgoingID(record['OutgoingFundsID']);
        setComment(record.Comment);
        setShowModalComment(true);        
        setShowModal(false);                
    };

    const onCloseDetail= () => {
        setShowModal(false);
        setDealDetail(null);
        setShowModalComment(false);  
        setComment('');
        setOutgoingID(0);
    };

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    };

    const onRefresh = () => {
        loadDeals();
    }

    return (
        <div>
            <div>
                <div className="uk-width-1-1" style={{ marginTop: '5px' , marginBottom: '15px', marginRight: '20px', textAlign: 'right'}}>
                    <button Name="btnRefresh" className="uk-button uk-button-default uk-button-green" onClick={onRefresh}>Refresh</button>
                </div>
                <NewEditableGrid
                    data={deals}
                    columnDetails={columnDetail}
                    isEditable={false}
                    editSaveFunction={save}
                    useIcons={true}
                    useExportButton={true}
                    tableName={'ReferredDeals'}
                    dynamicActions={[{name: 'View', function: onRowSelected, isIcon: true, icon: <SearchOutlined /> },
                    {name: 'Add Comment', function: onAddComment, isIcon: true, icon: <EditOutlined /> }
                    ]} />
            </div>
            <ShowDynamicInfo open={showModal} jsonDetail={dealDetail} columnDetails={columnDetail} title='Outgoing Fund Information' onClose={onCloseDetail}/>
            
            <Dialog open={showModalComment} onClose={onCloseDetail}>
                <DialogTitle>{'Outgoing Fund Comment'}</DialogTitle>
                <DialogContent>
                    <div >
                        <label className="uk-form-label" htmlFor="form-bank-status">Add Comment for Outgoing Fund {outgoingID} :</label>
                        <textarea className="uk-textarea" style={{marginTop: "5px"}} id="dealComment" type="text" placeholder="" value={comment} onChange={(e)=>setComment(e.target.value)}></textarea>
                    </div>                
                </DialogContent>
                <DialogActions>
                <button className="uk-button uk-button-green" key={"save"} disabled={!comment || comment.length === 0} type="button" onClick={save}>Save</button>
                    <button className="uk-button uk-button-green" key={"cancel"} type="button" onClick={onCloseDetail}>Close</button>
                </DialogActions>
            </Dialog>

            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
};

export default NewOutgoingFundsForm;
