import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';
import { Table } from '@material-ui/core';
import { Breadcrumb } from 'antd';
import React from 'react';
import { formatToCurrencyWithScale } from '../../helpers/FormatHelper';
import { getDealHeaderInfo } from '../../networking/NetworkingDeals';
import { getParametersByName } from '../../networking/NetworkingGlobalSystemParameters';
import { getStyle } from '../../styles/styles';
import AlertBox from '../shared/AlertBox';
import OutgoingFundsScreen from './../outgoingFunds/OutgoingFundsScreen';
import DealInformationForm from './DealInformationForm';
import ForwardDeposits from './ForwardDeposits';
import IncomingPaymentForm from './IncomingPaymentForm';
import OtherSettlementFunds from './OtherSettlementFunds';
import { getRiskScoreDetailsForDeals, getRiskScoreRanges } from '../../networking/NetworkingRiskScoreRange';
import { getIncomingFundsByDeal, getOutgoingFundsByDeal } from '../../networking/NetworkingFunds';
import { DealTypesNames } from '../../constants/DealTypesNames';
import RiskScoreDetailDialog from '../shared/Dialogs/RiskScoreDetailDialog';

var style = getStyle();

export default class DealInformationScreen extends React.Component {

    state = {
        activeTab: "Deal Information",
        customerID: 0,
        defaultPaymentID: 0,
        dealHeaderId: 0,
        status: '',
        buyAmount: 0,
        buyCurrency: '',
        sellCurrency: '',
        totalFees: 0,
        dealPaidStatus: '',
        amountToBeDisbursed: 0,
        sellAmount: 0,
        //
        loading: true,
        //incoming payments info
        activeCurrencies: [],
        incomingInstruments: [],
        dealHeaderInfo: {},
        listDealPayments: [],
        typeRecordsApply: 'Deal',
        //global parameters
        globalParameterNameCsv: 'VostroConversionDate,EnablePaymentConfirmationPrompt,EnableSavePendingPayments',
        globalParameters: [],
        lookUpTables: [],
        header: [],
        totalPaidByCustomer: 0,
        //popup
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        readOnly: false,

        showErrorAlert: false,

        fromSettlements: false,
        settlementPersonID: '',
        settlementBranchID: 0,
        fromRefunds: false,
        refundPersonID:'',
        refundBranchID: 0,
        refundID: 0,

        //RiskScore
        showModalViewRiskScoreCalculation   : false,
        listRiskScoreDetails                : [],
        riskValue                           : 0,
        dealRiskScoreText                   : 'N/A',
        colorRiskText                       : 'white',
        fontWeight                          : 'normal',
        backgroundColorRiskScore            : '#1d2b1d'
    }

    componentDidMount() {
        this.getGlobalParameters();

        var backgroundColorRiskScore =  localStorage.getItem('BackgroundColorRiskScore');
        if(backgroundColorRiskScore != null && backgroundColorRiskScore != '')
        {
            this.setState({backgroundColorRiskScore: backgroundColorRiskScore});
        }

        if(this.props.readOnly !== undefined)
        {
            this.setState({
                readOnly: this.props.readOnly
            });
        }

        if(this.props.fromSettlements !== undefined)
        {
            this.setState({
                fromSettlements: this.props.fromSettlements,
                settlementPersonID: this.props.settlementPersonID,
                settlementBranchID: this.props.settlementBranchID
            });
        }

        if(this.props.fromRefunds !== undefined)
        {
            this.setState({
                fromRefunds: this.props.fromRefunds,
                refundPersonID: this.props.refundPersonID,
                refundBranchID: this.props.refundBranchID,
                refundID: this.props.refundID
            });
        }

        this.setState({
            dealHeaderId: this.props.data, activeTab: (this.props.activeTab || this.state.activeTab)
        }, () => {
            this.loadDealInformation()
        });
    }

    loadDealInformation = () => {
        getDealHeaderInfo(this.state.dealHeaderId).then(
            (value) => {
                var dealHasData = false
                if (value && value.dealHeaderInfo) {
                    dealHasData = true
                    var dealHeaderInfo = {};
                    if (value.dealHeaderInfo != null) {
                        dealHeaderInfo = value.dealHeaderInfo[0];
                        console.table(dealHeaderInfo);
                    }

                    var activeCurrencies = [];
                    if (value.activeCurrencies != null) {
                        activeCurrencies = value.activeCurrencies;
                    }

                    var incomingInstruments = [];
                    if (value.incomingInstruments != null) {
                        incomingInstruments = value.incomingInstruments;
                    }

                    let lookUpTables = [];
                    if (value.lookUpTables != null) {
                        lookUpTables = value.lookUpTables;
                    }

                    if (dealHeaderInfo.Status === 'Pending for Approval') {
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Warning',
                            alertMessage: 'Deal is waiting for a customer approval. It cannot be processed',
                            readOnly: true
                        });
                    } if (dealHeaderInfo.Status === 'Voided') {
                        this.setState({
                            showAlert: false,
                            alertTitle: '',
                            alertMessage: '',
                            readOnly: true
                        });
                    } else {
                        this.setState({
                            showAlert: false,
                            alertTitle: '',
                            alertMessage: '',
                            //readOnly: false
                        });
                    }
                    //alert(JSON.stringify(dealHeaderInfo));
                    var defaultPaymentID = dealHeaderInfo.DefaultPaymentID;
                    if (this.props.paymentID !== undefined && this.props.paymentID > 0) {
                        defaultPaymentID = this.props.paymentID;
                    }
                    this.setState({
                        customerID: dealHeaderInfo["Customer ID"],
                        status: dealHeaderInfo.Status,
                        dealHeaderInfo: dealHeaderInfo,
                        buyAmount: dealHeaderInfo.BuyAmount,
                        buyCurrency: dealHeaderInfo.BCur,
                        sellCurrency: dealHeaderInfo.SCur,
                        totalFees: dealHeaderInfo.TotalFees,
                        dealPaidStatus: dealHeaderInfo.DealPaidStatus,
                        amountToBeDisbursed: dealHeaderInfo.TotalDisbursed,
                        sellAmount: dealHeaderInfo.SellAmount,
                        activeCurrencies: activeCurrencies,
                        incomingInstruments: incomingInstruments,
                        lookUpTables: lookUpTables,
                        totalPaidByCustomer: dealHeaderInfo.TotalPaidByCustomer,
                        defaultPaymentID: defaultPaymentID,
                    },() => this.clickButtonViewRiskScoreCalculation());
                }
                else 
                {
                    this.setState({
                        showErrorAlert: true,
                        alertTitle: 'Error',
                        alertMessage: 'An Error has ocurred. Please contact us.', 
                        loading: false
                    });
                }

                if(dealHasData){
                    if (value.dealPayments
                        && value.dealPayments != null) {
                        this.setState({
                            listDealPayments: value.dealPayments, loading: false
                        });
                    } else {
                        this.setState({
                            listDealPayments: [], loading: false
                        });
                    }
                }
            }
        );
    }

    updateDealObj = (obj) => {
        this.setState({ dealHeaderInfo: obj })
    }

    openIncomingTab = (incomingPaymentsTab)=>{
        if (incomingPaymentsTab) {
                this.setState({ activeTab: 'incomings' })
            }
    }

    handleBackLink() {
        this.props.backLink();
    }

    //load data
    getGlobalParameters() {
        getParametersByName(this.state.globalParameterNameCsv).then(
            (response) => {
                if (typeof response.parameters !== "undefined") {
                    this.setState({
                        globalParameters: response.parameters
                    })
                }
            }
        )
    }

    onClickTab = (tabName) => {
        this.setState({ activeTab: tabName });
    }

    closeAlert = (event) => {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
    }

    closeErrorAlert = (event) => {
        debugger
        if(this.props.backLink) {
            this.props.backLink();
        }
    }

    onClickDeal = (dealID) => {
        this.setState({dealHeaderId: dealID}, () => {
            this.loadDealInformation();
        })
    }

    clickButtonViewRiskScoreCalculation()
    {
        ////debugger

        var objDeal     = this.state.dealHeaderInfo;
        let listInco    = []
        let listOutg    = []

        getIncomingFundsByDeal(objDeal.DealHeaderID,0,0).then(
            (jsonResponse)=>{
                //console.log("getIncomingFundsByDeal: "+ JSON.stringify(jsonResponse));

                if(jsonResponse !== undefined)
                {
                    switch (jsonResponse.httpStatusCode) 
                    {
                        case 200:
                        {
                            if(jsonResponse.totalCount !== 0)
                            {
                                listInco = jsonResponse.incomingFunds
                            }
                            break;
                        }
                        default:
                        {
                            break;
                        }
                    }
                }

                getOutgoingFundsByDeal(objDeal.DealHeaderID,0,0).then(
                    (jsonResponse)=>{

                        console.log("getOutgoingFundsByDeal: "+ JSON.stringify(jsonResponse));
        
                        if(jsonResponse !== undefined)
                        {
                           switch (jsonResponse.httpStatusCode) 
                           {
                               case 200:
                               {
                                   if(jsonResponse.totalCount !== 0)
                                   {
                                        listOutg = jsonResponse.outgoingFunds
                                   }
                                   break;
                               }
                               default:
                               {
                                   break;
                               }
                           }
                        }

                        var modelRquest = 
                        {
                            "dealType"      : DealTypesNames[objDeal.dealTypeName],
                            "customerID"    : objDeal['Customer ID'],
                            "buyCurrency"   : objDeal.BCur,
                            "sellCurrency"  : objDeal.SCur,
                            "oOutgoingFunds": listOutg,
                            "oIncomingFunds": listInco,
                            dealID          : objDeal.DealHeaderID
                        }

                        let subtotalA = 0;
                        let subtotalB = 0;
                        let listRiskScoreDetails = [];

                        getRiskScoreDetailsForDeals(modelRquest).then(
                            (jsonResponse)=>{

                                if(jsonResponse.riskScoreDetails !== undefined)
                                {
                                    listRiskScoreDetails = jsonResponse.riskScoreDetails;
                                    listRiskScoreDetails.forEach(risk => {
                                        subtotalA += risk['Risk Importance'];
                                        subtotalB += risk['Total'];
                                    });

                                    let color   = this.state.colorRiskText;
                                    let text    = this.state.dealRiskScoreText;

                                    getRiskScoreRanges().then(
                                        (jsonResponse) => {
                                            //console.log("getRiskScoreRanges: "+ JSON.stringify(jsonResponse))
                                            if(jsonResponse.riskScores !== undefined)
                                            {
                                                /*{
                                                    "Score Range ID":10,
                                                    "Range From":0,
                                                    "Range To":25,
                                                    "Description":"Low risk",
                                                    "Color":"#FF80FF00",
                                                    "Status":"Authorized to deal",
                                                    "User Editable":false,
                                                    "Entity Type":"Customer"
                                                }*/
                            
                                                var listRSRAll = []
                                                
                                                listRSRAll = jsonResponse.riskScores;

                                                listRSRAll.forEach(riskScoreRange => {
                                                    if( riskScoreRange['Entity Type'] === 'Trade' && 
                                                        riskScoreRange['Range From'] <= Math.round(subtotalB/subtotalA) && 
                                                        Math.round(subtotalB/subtotalA) <= riskScoreRange['Range To'])
                                                    {
                                                        ////debugger
                                                        color = riskScoreRange['Color Hex'];
                                                        text  = riskScoreRange.Description;
                                                    }
                                                });
                                            }

                                            this.setState({
                                                listRiskScoreDetails    : listRiskScoreDetails,
                                                riskValue               : Math.round(subtotalB/subtotalA),
                                                dealRiskScoreText       : text === this.state.dealRiskScoreText ? text + Math.round(subtotalB/subtotalA) : text,
                                                colorRiskText           : color,
                                                fontWeight              : 'bolder'
                                            })
                                        }
                                    );
                                }
                            }
                        );
                    }
                );
            }
        );
    }

    render() {
        if (this.state.loading) {
            return (<div></div>);
        }
        
        if (this.state.showErrorAlert) {
            return (<div>
                <AlertBox open={this.state.showErrorAlert} onClose={this.closeErrorAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeErrorAlert.bind(this)} />
            </div>);
        }

        var classNameDealInfo = '', classNameIncoming = '',
            classNameOutgoing = '', classNameOtherSettlements = '',
            classNameDepositsTab = '';

        var currentScreen;
        switch (this.state.activeTab) {
            case 'dealInfo':
            default:
                classNameDealInfo = 'uk-active';
                currentScreen = (
                <div>
                    <div className="breadcrumb-div">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item className="breadcrumb-item-bold">Deal Information</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <DealInformationForm dealHeaderId={this.props.data}
                        dealHeaderInfo={this.state.dealHeaderInfo}
                        lookUpTables={this.state.lookUpTables}
                        dealPayments={this.state.listDealPayments}
                        clickDeal={this.onClickDeal}
                        reloadDealHeaderInfo={this.loadDealInformation} 
                        readOnly={this.state.readOnly}
                        fromSettlements={this.state.fromSettlements}
                        fromRefunds={this.state.fromRefunds}
                        //Open Incoming Tab
                        openIncomingTab={this.openIncomingTab}
                        updateDealObj={this.updateDealObj}
                    />
                </div>
                );
                break;
            case 'incomings':
                classNameIncoming = 'uk-active';
                currentScreen = (
                    <IncomingPaymentForm dealHeaderID={this.props.data}
                        customerID={this.state.customerID}
                        buyCurrency={this.state.buyCurrency}
                        activeCurrencies={this.state.activeCurrencies}
                        incomingInstruments={this.state.incomingInstruments}
                        globalParameters={this.state.globalParameters}
                        reloadDealHeaderInfo={this.loadDealInformation}
                        typeRecordsApply={this.state.typeRecordsApply}
                        data={this.state.defaultPaymentID}
                        paymentOpenFromBoard={false} readOnly={this.state.readOnly} 
                        fromSettlements={this.state.fromSettlements}
                        dealHeaderInfo={this.state.dealHeaderInfo}
                        settlementPersonID={this.state.settlementPersonID}
                        settlementBranchID={this.state.settlementBranchID}
                        totalDue={this.state.buyAmount + this.state.totalFees - this.state.totalPaidByCustomer}
                    />
                );
                break;
            case 'outgoings':
                classNameOutgoing = 'uk-active';
                currentScreen = (<OutgoingFundsScreen 
                                    dealID={this.props.data} 
                                    readOnly={this.state.readOnly} 
                                    reloadDealHeaderInfo={this.loadDealInformation}
                                    fromRefunds={this.state.fromRefunds}
                                    id={this.state.refundID}
                                    refundPersonID={this.state.refundPersonID}
                                    refundBranchID={this.state.refundBranchID}
                                    sellAmount = {this.state.sellAmount }//- this.state.totalFees}
                                />);
                break;
            case 'otherSettlements':
                classNameOtherSettlements = 'uk-active';
                currentScreen = (
                    <fieldset disabled={this.state.readOnly}>
                        <OtherSettlementFunds customerID={this.state.customerID} dealHeaderID={this.state.dealHeaderId} buyCCY={this.state.buyCurrency}
                            reloadDealHeaderInfo={this.loadDealInformation} readOnly={this.state.readOnly} />
                    </fieldset>
                );
                break;
            case 'deposits':
                classNameDepositsTab = 'uk-active';
                currentScreen = (<ForwardDeposits buyCurrency={this.state.buyCurrency}
                    customerID={this.state.customerID}
                    dealHeaderID={this.props.data}
                    readOnly={this.state.readOnly} />);
                break;
        }

        //alert(JSON.stringify(this.state.dealHeaderInfo));
        var incomingPaymentsTab, outgoingFundsTab, otherSettlementsTab, depositsTab;
        if(this.state.dealHeaderInfo.Type !== 'Forward'
            || this.state.dealHeaderInfo.ForwardType !== 'Open'){
            if(!this.state.fromRefunds){
                incomingPaymentsTab = (<li className={classNameIncoming}><a onClick={() => this.onClickTab('incomings')}>Incoming Payments</a></li>);
                if(!this.state.fromSettlements){
                    otherSettlementsTab = (<li className={classNameOtherSettlements}><a onClick={() => this.onClickTab('otherSettlements')}>Other Settlement Funds</a></li>);
                }
            }
            outgoingFundsTab = (<li className={classNameOutgoing}><a onClick={() => this.onClickTab('outgoings')}>Outgoing Funds</a></li>);
        }

        if (this.state.dealHeaderInfo.Type === 'Forward') {
            depositsTab = (<li className={classNameDepositsTab}><a onClick={() => this.onClickTab('deposits')}>Deposits</a></li>);
        }
        var dealTitle = (
            <div className="uk-width-1-2">
                <h3 className="uk-heading-divider" style={style.titleStyle} >
                    <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                        <i className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle' }}></i>
                    </button>
                    Deal Information
                </h3>
            </div>
        )
        if (this.props.mainTitle != null) {
            dealTitle = (<div className="uk-width-1-3"></div>);
        }
        if (this.props.noBreadcrumb) {
            dealTitle = null;
        }
        var dealTable = (
            <div style={{ display: 'inline-flex', paddingBottom: '5px'}}>
                {/*<div className="" style={{ textAlign: "right", marginRight: '10px' }}>
                    <label className="uk-form-label" style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>Deal number: {this.state.dealHeaderId}</label>
        </div>*/}
                {/*<div style={{ textAlign: "right", marginRight: '10px' }}>
                    <h3 className="" style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>Deal ID: {this.state.dealHeaderId}</h3>
                </div>*/}
                <div className="uk-width-1-4" style={{marginRight: '20px' }}>
                            <Badge
                                anchorOrigin={{
                                    vertical    : 'top',
                                    horizontal  : 'right',
                                }}
                                badgeContent={
                                    <InfoIcon 
                                        uk-tooltip  = {'Risk calculation'}
                                        style       = {{ color: '#6E9C3A' }}
                                    />
                                }
                                disabled={true}
                            >
                                <div 
                                    className   = "" 
                                    type        = "button" 
                                    style       = {{
                                        borderRadius    : 10,
                                        border          : '1px solid #e5e5e5',
                                        justifyContent  : 'center',
                                        display         : 'flex',
                                        alignItems      : 'center',
                                        paddingLeft      : 10,
                                        paddingRight     : 10,
                                        backgroundColor : this.state.backgroundColorRiskScore
                                    }}
                                >
                                    <label 
                                        onClick     = { () =>{
                                            this.setState({showModalViewRiskScoreCalculation: true});
                                        }}
                                        className   =""
                                        style       = {{ color: this.state.colorRiskText, textAlign: 'center', fontWeight: this.state.fontWeight, cursor:'pointer'}}
                                        id          = "DealRiskScore"
                                    >{this.state.dealRiskScoreText}
                                    </label>
                                </div>
                            </Badge>
                        </div>
                <div className="" style={{textAlign: 'center'}}>
                    <Table border="1" bordercolor="#E5DBD8">
                        <tr>
                            <th style={{paddingLeft: '2px', paddingRight: '2px'}}>Deal</th>
                            <th style={{width: '1%', whiteSpace: 'nowrap', paddingLeft: '2px', paddingRight: '2px'}}>Total Due</th>
                            <th style={{paddingLeft: '2px', paddingRight: '2px'}}>Settlement</th>
                            <th style={{paddingLeft: '2px', paddingRight: '2px'}}>Disbursements</th>
                        </tr>
                        <tr>
                            <td align="middle" style={{paddingLeft: '2px', paddingRight: '2px'}}>{this.state.status}</td>
                            <td align="middle"  style={{width: '1%', whiteSpace: 'nowrap',paddingLeft: '2px', paddingRight: '2px'}}>{`${formatToCurrencyWithScale(this.state.buyAmount + this.state.totalFees - this.state.totalPaidByCustomer, this.state.buyCurrency)} ${this.state.buyCurrency}`}</td>
                            <td align="middle" style={{paddingLeft: '2px', paddingRight: '2px'}}>{this.state.dealPaidStatus}</td>
                            <td align="middle" style={{paddingLeft: '2px', paddingRight: '2px'}}>{`${formatToCurrencyWithScale(this.state.sellAmount - this.state.amountToBeDisbursed, this.state.sellCurrency)} ${this.state.sellCurrency}`}</td>
                        </tr>
                    </Table>
                </div>
            </div>);
        var dealScreen = (
            <div>
                <div>
                    {dealTitle}
                </div>
                <ul className="uk-tab icons-container-properties" >
                    <div className="uk-tab icon-container-properties uk-width-3-5">
                        <li className={classNameDealInfo}><a onClick={() => this.onClickTab('dealInfo')}>Deal Information</a></li>
                        {incomingPaymentsTab}
                        {outgoingFundsTab}
                        {otherSettlementsTab}
                        {depositsTab}
                    </div>
                    <div className="uk-width-2-5 input-number">{dealTable}</div>
                </ul>
                {currentScreen}
            </div>
        );
        return (
            <div>
                <div>
                    {this.props.mainTitle != null &&
                        <div className="breadcrumb-div">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item className="breadcrumb-item" onClick={this.handleBackLink.bind(this)}>{this.props.mainTitle}</Breadcrumb.Item>
                                <Breadcrumb.Item className="breadcrumb-item-bold">Deal Information - Deal ID {this.state.dealHeaderId}</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    }{/*<h3 className="uk-heading-divider" style={style.titleStyle}>Customers</h3>*/}
                    {dealScreen}

                </div>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <AlertBox open={this.state.showErrorAlert} onClose={this.closeErrorAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeErrorAlert.bind(this)} />
                {/*View Risk Score Calculation*/}
                <RiskScoreDetailDialog
                    entityType                   = {'Trader'}
                    dealId                       = {this.state.dealHeaderID}
                    riskScoreDetails             = {this.state.listRiskScoreDetails}
                    open                         = {this.state.showModalViewRiskScoreCalculation}
                    onCloseRiskScoreDetailDialog = {() =>{this.setState({showModalViewRiskScoreCalculation: false})}}
                />
            </div>
        );
    }
}