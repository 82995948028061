import React from 'react';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getAllRoles, getUsersInRole } from '../../../networking/NetworkingRoles';
import { deleteUser, getUsersByEntityType, getUsersRol } from '../../../networking/NetworkingUsers';
import { getStyle } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DynamicSelect from '../../shared/DynamicSelect';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { SearchOutlined } from '@ant-design/icons';

var style = getStyle();

const itemsPerPage = getDefaultPagingNumberOfItems();

export default class UserForm extends React.Component {
    state = {
        roles: [],
        role:'',
        usersInRole: [],
        selectedRole: {},
        selectedUser: {},
        totalPagesUser:0,
        pagesUser:1,
        newSearch: false,
        isCreate:false,
        userToRole:'',
        //Delete Message
        readyToDeleteRole: {},
        readyToDeleteUser: {},
        alertTitle:'',
        alertMessage:'',
        showDeleteAlert: false,
        showDeleteAlert2: false,
        showErrorAlert: false,
        showSuccessDeleteAlert: false,
        showSuccessDeleteAlert2: false,
        grid_data: [],
        columnDetail: {},
    }
    
    closeDeleteAlert = () =>{
        this.setState({ showDeleteAlert: false, readyToDeleteUser: {} });
    }

    closeErrorAlert = () => {
        this.setState({showErrorAlert: false});
    }

    yesDeleteAlert = () =>{   
        deleteUser(this.state.readyToDeleteUser['UserID'], this.state.readyToDeleteUser['UserName']).then(
            (json) => {
                if (json.httpStatusCode !== 200) {
                    this.setState({ showDeleteAlert: false, showErrorAlert: true, errorMessage: json.Message });
                } else {
                    this.setState({ showDeleteAlert: false, showSuccessDeleteAlert: true });
                    this.loadUserGrid();
                    //this.updateAllUsers();
                }
            }
        )
    }

    okSuccessAlert = () => {
        this.setState({ showSuccessDeleteAlert: false });
    }

    componentDidMount(){
        this.loadUserGrid()
        this.updateRolesTable()
        //this.updateAllUsers()
    }
    updateAllUsers = () =>{
        getUsersByEntityType('U').then(
            (json)=>{
                console.log(json)
                var totalCount = json.UsersByEntityType.length;
                var pages = Math.ceil(totalCount/itemsPerPage);
                this.setState({usersInRole: this.transformList(json.UsersByEntityType, 'getUsersByEntityType'), totalPagesUser: pages, newSearch: false})
            }
        );
    }

    loadUserGrid(){
        getUsersByEntityType('U').then(
            (json)=>{
                if (json != null && json.result != null) {
                    let _columnDetail = [];
                    if (json.columnDetail != null) {
                        _columnDetail = JSON.parse(json.columnDetail);
                    }
                    this.setState({grid_data: json.result, columnDetail: _columnDetail});
                }
                else {
                    this.setState({grid_data: []});
                }
            }
        );
    }

    filterUserByName = async (name) =>{
        var json = await getUsersByEntityType('U');
        var allUsers = this.transformList(json.UsersByEntityType, 'getUsersByEntityType');
        var helper = []
        allUsers.forEach(value =>{
            if(value.UserName.includes(name)){
                helper.push(value)
            }
        })
        var totalCount = helper.length!==undefined?helper.length:0;
        var pages = Math.ceil(totalCount/itemsPerPage);
        this.setState({usersInRole: helper, totalPagesUser: pages, newSearch: false})
    }
    handleSearchClick = () =>{   
        var objRecord = [
                            {
                            'ApplicationName': 'ForeignExchange',
                            'P_text': this.state.userToRole,
                            'P_roleOption': this.state.role!=='select'?this.state.role:''
                            }
                        ]
        objRecord.forEach((value)=>{
            if(value.P_text!==''&&value.P_roleOption!==''){
                getUsersRol(objRecord[0]).then(
                    (json)=>{
                        console.log(json)
                        var obj = json.length!==undefined?this.transformList(json, 'getUsersInRole'):[]
                        var totalCount = json.length!==undefined?json.length:0;
                        var pages = Math.ceil(totalCount/itemsPerPage);
                        this.setState({usersInRole: obj, totalPagesUser: pages, newSearch: false})
                    }
                );
            }else if(value.P_text===''&&value.P_roleOption!==''){
                this.updateUsersFromRoleTable(this.state.role)
            }else if(value.P_text!==''&&value.P_roleOption===''){
                this.filterUserByName(value.P_text)
            }else{                
                this.loadUserGrid()
                //this.updateAllUsers()
            }
        });
    }
    transformListRoles(list){
        var helper = [];
        for(var i = 0; i<list.length; i++){
            helper.push({'name':list[i]['Role Name'],
                        'value': list[i]['Role Name']});
        }
        return helper;
    }
    transformList(collection, from){

        var helper = collection;
        for (var i = 0; i<helper.length; i++)
        {
            switch (from) 
            {
                default:
                case 'getUsersByEntityType':
                {
                    if(helper[i] !== null)
                    {
                        if(helper[i].IsLock){
                            helper[i].IsLock='Yes'
                        }else{
                            helper[i].IsLock='No'
                        }
                    }else
                    {
                        helper.splice(i, 1);
                        i--; // Update i to not skip a record
                    }
                    break;
                }
                case 'getUsersInRole':
                {
                    if(helper[i] !== null)
                    {
                        if(helper[i].Islock){
                            helper[i].Islock='Yes'
                        }else{
                            helper[i].Islock='No'
                        }
                    }else
                    {
                        helper.splice(i, 1);
                        i--; // Update i to not skip a record
                    }
                    break;
                }
            }
            
        }
        return helper;
        
    }
    
    handleDeleteClick = (obj) => {
        console.group("User handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({showDeleteAlert: true, readyToDeleteUser: obj});
    }

    updateRolesTable(){
        getAllRoles("ForeignExchange",1,200).then(
            (value) => {
                var helper = this.transformListRoles(value.Roles);
                this.setState({roles: helper});
            }
        );
    }

    updateUsersFromRoleTable(obj){
        getUsersInRole('ForeignExchange',obj).then(
            (value)=>{
                var obj = value.length!==undefined?this.transformList(value, 'getUsersInRole'):[]
                var totalCount = value.length!==undefined?value.length:0;
                var pages = Math.ceil(totalCount/itemsPerPage);
                this.setState({usersInRole: obj, totalPagesUser: pages, newSearch: true});
            }
        );
    }

    handleRoleRowClick = (obj) =>{
        console.log(obj);
        this.setState({selectedRole: obj});
        getUsersInRole('ForeignExchange',obj['Role Name']).then(
            (value)=>{
                if(value.length!==undefined){
                    var helper = []
                    for (var i = 0; i < value.length; i++) {
                        if(value[i]!==null){
                            helper.push(value[i])  
                        }               
                    }
                    var totalCount = value.length;
                    var pages = Math.ceil(totalCount/itemsPerPage);
                    console.log(pages);
                    this.setState({totalPagesUser: pages, newSearch: false,usersInRole: this.transformList(helper, 'getUsersInRole')})
                }else{
                    this.setState({usersInRole: []});
                }
            }
        );
    }
    handleUserRowClick(objUser){
        console.log(objUser);
        this.props.isCreateClick(false);
        this.props.selectedUser(objUser)
        this.props.getStep(2);
    }

    handleRowClick(objUser){
        console.log(objUser);
        this.props.isCreateClick(false);
        this.props.selectedUser(objUser)
        this.props.getStep(2);
    }

    clearData(){
        this.setState({customers: [], searchedCustomerName: ''});
    }
    changePageUser = (value) =>{
        this.setState({pagesUser: value, newSearch: false});
    }
    isCreateClick = (value) =>{
        this.setState({isCreate: value},()=>this.props.selectedUser(this.state.selectedUser))
    }

    getAllRoles = async () =>{
        var json = await getAllRoles("ForeignExchange",0,0);
        var allRoles = this.transformListRoles(json.Roles);
        return allRoles;
    }

    getUsers = async () =>{
        var json = await getUsersByEntityType('U');
        var allUsers = this.transformList(json.UsersByEntityType, 'getUsersByEntityType');
        return allUsers;
    }

    getAllUsers = (list,pages) =>{
        this.setState({usersInRole: this.transformList(list,''), totalPagesUser: pages, newSearch: false})
    }
    handleCreateClick = () =>{
        this.props.isCreateClick(true);
        this.isCreateClick(true);
        this.props.getStep(2);
    }
    handleUpdateUserToRole(event){
        this.setState({userToRole: event.target.value});
    }
    handleUpdateRoles = (value) =>{
        if(value.value!==undefined){
            this.setState({role: value.value});
        }else{}
    }

    render(){
        return(
                <div className="uk-grid">
                    {/* <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">User Name</label>
                        <div className="uk-form-controls">
                            <input className="uk-input" id="userToRole" value={this.state.userToRole} type="text"onChange={this.handleUpdateUserToRole.bind(this)}/>
                        </div>
                    </div>
                    <div className="uk-width-1-2 uk-form-stacked">
                        <label className="uk-form-label">Roles</label>
                        <div className="uk-form-controls">
                            <DynamicSelect className="uk-select" getValue={this.handleUpdateRoles} placeholder="Select your item" data={this.state.roles} objValue={{value: this.state.role, name: this.state.role}}/>
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" onClick={this.handleCreateClick} type="button">Create</button>
                        <button className="uk-button uk-button-green" onClick={this.handleSearchClick} type="button">Search</button>
                    </div> */}
                    <div className="uk-width-1-1 uk-form-stacked">
                        <NewEditableGrid
                            data={this.state.grid_data}
                            columnDetails={this.state.columnDetail}
                            isEditable={false}
                            useDeleteButton={true}
                            // enableClick={true} 
                            // clickFunction={this.handleRowClick.bind(this)}
                            deleteFunction={this.handleDeleteClick}
                            useExportButton={true}
                            tableName="Users"
                            canAdd
                            overrideAddFunction={this.handleCreateClick}
                            dynamicActions={[{ name: 'Edit', function: this.handleRowClick.bind(this), isIcon: false }]}
                            />
                        {/*<NewDynamicTable data={this.state.usersInRole} 
                            hiddenHeaders   = {[
                                'UserID', 'FirstName', 'LastName', 'PhoneNumber','BranchID', 'CountryID','BusinessUnitID', 'ReportsToUserID',
                                'DealsLimit', 'RequireApproval','AllowPreviousDayVoid', 'AllowSingleDealRoll', 'AllowSameDayVoid', 'AllowTradeUpdates', 
                                'TraderCommissionPercentage', 'UserCommissionPercentage', 'CommissionOverride', 'RestrictCustomerAccess', 
                                'noDaysPasswordExpires', 'noPreviousPwds', 'UpdatedBy', 'UpdateToken', 'PasswordQuestion', 'Comment', 'IsApproved',
                                'CreateDate', 'LastLoginDate', 'LastActivityDate', 'LastPasswordChangedDate', 'LastLockoutDate', 'ApplicationName', 'Teller Branch'
                                //Case By Roles
                                , 'MobileName', 'resetPassword', 'CommissionPercentage', 'CreatedBy', 'CreatedOn', 'ShowLocked', 'TellerBranchID', 'UpdatedOn'
                            ]} 
                            enableClick={true} 
                            clickFunction={this.handleUserRowClick.bind(this)} 
                            newSearch={true} 
                            useDeleteButton={true}
                            deleteFunction={this.handleDeleteClick} 
                            usePaginator={true}
                            changePage={this.changePageUser}
                            numberOfPages={this.state.totalPagesUser}
                            pageNumber = {this.state.pagesUser}
                            numberPerPage = {getDefaultPagingNumberOfItems()}
                            enableSortByHeader={true}
                            useFilter={true} 
                            filterFunction={this.getUsers}
                        />*/}
                    </div>
                    <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert} title="Are you sure you want to delete this User?"
                        message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert} noClick={this.closeDeleteAlert} />
                    <AlertBox id="success-delete-user" open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert} title="User successfully removed." message="" type="Ok" okClick={this.okSuccessAlert} />
                    <AlertBox id="error-message-user" open={this.state.showErrorAlert} onClose={this.closeErrorAlert} title="Error" message={this.state.errorMessage} type="Ok" okClick={this.closeErrorAlert}/>
                </div>
                
        );
    }
}