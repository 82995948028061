import { DialogActions } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import AppContext from '../../AppContext';
import { getDefaultPagingNumberOfItems } from '../../helpers/PackageJsonHelper';
import { getContactsByCustomer,GetActiveContactsByCustomer } from '../../networking/NetworkingContacts';
import { sendDealReportByEmail } from '../../networking/NetworkingDeals';
import AlertBox from '../shared/AlertBox';
import NewDynamicTable from '../shared/NewDynamicTable.old';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class EmailConfirmationDialog extends React.Component {
    static contextType = AppContext;
    state = {
        contacts: [],
        recipients: [],
        newSearch: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        totalPages: 0,
        currentPage: 1,
        docType: 'Confirmation',
        language: 'English'
    };

    componentDidMount() {
        if (this.props.dealHeaderId != null && this.props.customerId != null) {
            this.refreshContacts();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.setState({ open: true });
        }
        if (this.props.dealHeaderId != null && this.props.dealHeaderId !== prevProps.dealHeaderId) {
            if (this.props.customerId != null && this.props.customerId !== prevProps.customerId) {
                this.refreshContacts();
            }
        }        
    }

    refreshContacts = async () => {
        ////debugger
        this.context.startLoading();
        const json = await GetActiveContactsByCustomer(this.props.customerId, 1, 100);
        this.context.finishLoading();
        if (json != null && json.contacts != null) {
            //const authorizedTradeAndActive = () => (c) => c['Status$'] === 'Active' && c['ContactType$'] === 'Authorized Trader';
            const contacts = json.contacts;//.filter(authorizedTradeAndActive()).map((c) => ({ Name: c.Name, ['Online Confirmation Email']: c['Online Confirmation Email'], Phone: c.Phone }));
            const totalCount = contacts.length;
            if(this.props.contacts_length)
            {
            this.props.contacts_length(contacts.length);
            }
            const pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
            if (json.contacts != null) {
                this.setState({ contacts: contacts, totalPages: pages, newSearch: true, dealHeaderId: this.props.dealHeaderId });
            } else {
                this.setState({ contacts: [], totalPages: pages, newSearch: true, dealHeaderId: this.props.dealHeaderId });
            }
        }
    };

    handleSendEmailClick = async () => {
        ////debugger
        const model = {
            DealHeaderID: this.props.dealHeaderId,
            Language    : this.state.language,
            DealMailInfo: this.state.recipients.map(c => ({ 
                MainInfo    : c['Online Confirmation Email'],
                DocType     : this.state.docType,
                MobilePhone : c['Mobile Phone']
            })),
            SendTrader  : true
        };
        this.context.startLoading();
        const response = await sendDealReportByEmail(model);
        this.context.finishLoading();
        switch (response.httpStatusCode) {
            case 200: {
                this.setState({ showAlert: true, alertMessage: 'Emails successfully sent.', alertTitle: 'Success' });
                break;
            }
            default: {
                this.setState({ alertMessage: 'There was an error sending the email. Please try again.', showAlert: true, alertTitle: 'Error' });
                break;
            }
        }
    }

    handleCheckboxClick = (contact, checked) => {
        let recipients = this.state.recipients;
        if (checked) {
            recipients.push(contact);
        } else {
            recipients = recipients.filter((c) => c['Online Confirmation Email'] !== contact['Online Confirmation Email']);
        }
        this.setState({ recipients });
    };

    changePage = (value) => {
        this.setState({ currentPage: value });
    };

    getAllContacts = async () => {
        var json = await GetActiveContactsByCustomer(this.props.customerId, 0, 0);
        var contacts = json.contacts;
        this.props.contacts_length(contacts.length);
        return contacts;
    };

    close = () => {
        this.props.onClose();
        this.setState({ open: false, recipients: [] });
    }

    dismissAlert = () => {
        this.setState({ showAlert: false });
    }

    render() {
        return (
            <React.Fragment>
                <Dialog open={this.props.open} onClose={this.close} fullWidth={true} maxWidth={'md'}>
                    <DialogTitle id="current-attachment-dialog-title" onClose={this.close}>Email Confirmation</DialogTitle>
                    <DialogContent dividers style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 0, paddingBottom: 0 }}>
                        <NewDynamicTable
                            id="ndt-email-confirmation"
                            data={this.state.contacts}
                            newSearch={this.state.newSearch}
                            hiddenHeaders={['PersonID']}
                            checkboxFunction={this.handleCheckboxClick}
                            useCheckBox={true}
                            selectedRows={this.state.recipients}
                            usePaginator={true}
                            changePage={this.changePage}
                            numberOfPages={this.state.totalPages}
                            pageNumber={this.state.currentPage}
                            numberPerPage={getDefaultPagingNumberOfItems()}
                            enableSortByHeader={true}
                            useFilter={true}
                            filterFunction={this.getAllContacts}
                            hiddenHeaders={['Email']}
                        />
                    </DialogContent>
                    <DialogActions>
                        <button className="uk-button uk-button-green" onClick={this.handleSendEmailClick}>Send Email Confirmation</button>
                        <button className="uk-button uk-button-green" onClick={this.close}>
                            Close
                        </button>
                    </DialogActions>
                </Dialog>
                <AlertBox
                    id="email-confirmation-dialog-alert"
                    key="email-confirmation-dialog-alert"
                    open={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.dismissAlert}
                />
            </React.Fragment>
        );
    }
}
