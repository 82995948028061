import { Dialog, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import MTypography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Input, Button, Popconfirm, Select, Space, Table, Typography } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { formatDate } from '../../helpers/FormatHelper';
import { searchCustomerMSB } from '../../networking/NetworkingCustomers';
import {
    deleteFileUploaded,
    getTransactionUploadDetails,
    searchTransactionUpload,
    updateFiletransactionUpload,
    updateTransactionDetailComment
} from '../../networking/NetworkingTransactionUpload';
import { getStyle } from '../../styles/styles';
import AlertBox from '../shared/AlertBox';
import DatePicker from '../shared/DatePicker';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';
import NewDynamicTable from '../shared/NewDynamicTable';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import TheCSVButton from '../shared/TheCSVButton';
import NewEditableGrid from '../shared/NewEditableGrid';

var style = getStyle();
const defaultRowsSize = window.DEFAULT_ROWS_SIZE??20;
const { Option } = Select;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <MTypography variant="h6">{children}</MTypography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const UploadFileForm = ({ file = {}, onClose }) => {
    return (
        <div>
            These are the details of the selected {file.Id}
            <div className="uk-width-1-1">
                <br></br>
                <button className="uk-button uk-button-green uk-align-right" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

const TableDetails = ({ data = [], hiddenColumns = [], inputFields = [], updateData = (f) => f, triggerUpdate }) => {
    const [newData, setNewData] = useState(data);
    const headers = (newData != null && newData.length > 0) ? Object.getOwnPropertyNames(data[0]).filter((headerName) => !hiddenColumns.includes(headerName)) : [];

    useEffect(() => {
        setNewData(data);
    }, [data, triggerUpdate]);

    if (newData.length === 0) {
        return (
            <div>
                <NewDynamicTable key="uploaded-files-dummy" data={[]} clickFunction={(f) => f} />
            </div>
        );
    }

    return (
        <div style={{ overflow: 'auto', maxHeight: 600 }}>
            <table key="detailsTable" className="uk-table uk-table-small uk-table-responsive uk-table-hover uk-table-divider border-table uk-table-striped">
                <thead>
                    <tr>
                        {headers.map((headerName, i) => (
                            <th
                                style={{ cursor: 'pointer', textAlign: 'center', verticalAlign: 'middle', fontSize: '0.75em' /*textTransform: 'uppercase'*/ }}
                                className="uk-table-shrink"
                                key={'dh' + i}
                            >
                                {headerName}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {newData.map((x, i) => {
                        return (
                            <tr key={'det' + i}>
                                {headers.map((element, idx) => {
                                    if (inputFields.includes(element)) {
                                        return (
                                            <td key={'td-' + i + element + idx} className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                <input
                                                    key={i + element + idx}
                                                    className="uk-input"
                                                    value={x[element]}
                                                    style={{ minWidth: 180 }}
                                                    onChange={(event) => {
                                                        x[element] = event.target.value;
                                                        x['RowUpdated'] = true;
                                                        updateData(data);
                                                    }}
                                                />
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td key={'td-' + i + element + idx} className="uk-preserve-width" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                {x[element]}
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default function FilesReviewScreen() {
    const [form] = Form.useForm();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [customerIdSelected, setCustomerIdSelected] = useState(0);
    const [msbCustomerList, setMsbCustomerList] = useState([]);
    const [transactionUploadFiles, setTransactionUploadFiles] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [fileTransactionDetails, setFileTransactionDetails] = useState([]);
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [showAlertTitle, setShowAlertTitle] = useState('');
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [showAlertConfirmation, setShowAlertConfirmation] = useState(false);
    const [showAlertConfirmationTitle, setShowAlertConfirmationTitle] = useState('');
    const [showAlertConfirmationMessage, setShowAlertConfirmationMessage] = useState('');
    const [fileToDeleteId, setFileToDeleteId] = useState('');

    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const [currentPage, setCurrentPage] = useState(1);
    const [editingKey, setEditingKey] = useState('');
    const [columns, setColumns] = useState({});

    useEffect(() => {
        const loadMsbCustomers = async () => {
            const json = await searchCustomerMSB('');
            if (json != null && json.customers != null) {
                const _customers = json.customers.map((c) => ({ ...c, name: c.label }));
                setMsbCustomerList(_customers);
            }
        };
        loadMsbCustomers();
    }, []);

    const isEditing = (record) => record.Id === editingKey;

    const edit = (record, event) => {
        event.stopPropagation();
        form.setFieldsValue({
            UpdateToken: '',
            ...record
        });
        setEditingKey(record.Id);
    };

    const cancel = (page) => {
        setEditingKey('');
        if (typeof page === 'number') {
            setCurrentPage(page);
        }
        form.resetFields();
    };

    const onRefresh = () => {
        setFromDate('');
        setToDate('');
        setCustomerIdSelected(0);
        setTransactionUploadFiles([]);
        setSelectedFile({});
        setFileTransactionDetails([]);
        setEditingKey('');
    };

    const loadFilesUploaded = async (_customerID, _fromDate, _toDate) => {
        setLoading(true);
        const json = await searchTransactionUpload(_customerID, _fromDate, _toDate);
        let _transactionUploadFiles = [];
        if (json != null && json.result != null) {
            setTransactionUploadFiles(json.result);
            setColumns(JSON.parse(json.columnDetail));
        } else {
            setTransactionUploadFiles([]);
        }
        if (selectedFile != null) {
            const filterTransactionsCurrent = _transactionUploadFiles.filter((x) => x.Id === selectedFile.Id);
            if (filterTransactionsCurrent.length === 1) {
                const _selectedFile = filterTransactionsCurrent[0];
                setSelectedFile(_selectedFile);
                loadFileTransactionDetails(_selectedFile.Id);
            }
        }
        setLoading(false);
    };

    const loadFileTransactionDetails = async (selectedFileID) => {
        setLoading(true);
        const json = await getTransactionUploadDetails(selectedFileID);
        if (json != null && json.TransactionUpload != null) {
            setFileTransactionDetails(json.TransactionUpload
                .map(f => ({ ...f, key: f.Id})));
        } else {
            setFileTransactionDetails([]);
        }
        setLoading(false);
    };

    const updateData = (data) => {
        setFileTransactionDetails(data);
        setTriggerUpdate(!triggerUpdate);
    };

    const onCustomerSelected = (obj) => {
        const _customerID = obj.value !== undefined ? obj.value : 0;
        setCustomerIdSelected(_customerID);
        setTransactionUploadFiles([]);
        setSelectedFile({});
        setFileTransactionDetails([]);
        if (_customerID > 0) {
            loadFilesUploaded(_customerID, fromDate, toDate);
        }
    };

    const onDateChange = (date) => {
        const _fromDate = formatDate(date);
        setFromDate(_fromDate);
        if (customerIdSelected > 0) {
            loadFilesUploaded(customerIdSelected, _fromDate, toDate);
        }
    };

    const onToDateChange = (date) => {
        const _toDate = formatDate(date);
        setToDate(_toDate);
        if (customerIdSelected > 0) {
            loadFilesUploaded(customerIdSelected, fromDate, _toDate);
        }
    };

    const onRowSelected = (obj) => {
        setSelectedFile(obj);
        setFileToDeleteId(obj.Id);
        loadFileTransactionDetails(obj.Id);
    };

    const onDeleteRowSelected = (obj) => {
        setShowAlertConfirmation(true);
        setShowAlertConfirmationTitle('Warning');
        setShowAlertConfirmationMessage('Are you sure you want to delete this File? This action can not be undone.');
    };

    const onDeleteFile = async () => {
        setLoading(true);
        setShowAlertConfirmation(false);
        const json = await deleteFileUploaded(fileToDeleteId);
        if (json.httpStatusCode === 200) {
            setShowAlert(true);
            setShowAlertTitle('Success');
            setShowAlertMessage('File deleted successfully');
        } else {
            setShowAlert(true);
            setShowAlertTitle('Error');
            setShowAlertMessage('File deleted successfully');
            loadFilesUploaded(customerIdSelected, fromDate, toDate);
        }
        setLoading(false);
    };

    const onSaveComments = () => {
        let fileTransactionDetailsToSave = fileTransactionDetails.filter((x) => x.RowUpdated);
        if (fileTransactionDetailsToSave.length === 0) {
            setShowAlert(true);
            setShowAlertTitle('Warning');
            setShowAlertMessage('Nothing to Save');
            return;
        }
        setLoading(true);
        let messageLog = [];
        let count = fileTransactionDetailsToSave.length;
        fileTransactionDetailsToSave.forEach((x, i) => {
            updateTransactionDetailComment(x['TransactionUploadId'], x['DetailId'], x['Comment'], localStorage.getItem('UserID'), x['UpdateToken'])
                .then((json) => {
                    if (json.httpStatusCode === 200) {
                        messageLog.push(<label key={'lb' + i}>{'Transaction ' + x['TransactionNo'] + ' updated successfully'}</label>);
                        messageLog.push(<br key={'br' + i} />);
                    } else {
                        messageLog.push(<label key={'lb' + i}>{'Transaction  ' + x['TransactionNo'] + ' error:  ' + json.Message}</label>);
                        messageLog.push(<br key={'br' + i} />);
                    }
                })
                .finally(() => {
                    count--;
                    if (count === 0) {
                        setLoading(false);
                        setShowAlert(true);
                        setShowAlertTitle('Information');
                        setShowAlertMessage(messageLog);
                        loadFileTransactionDetails(selectedFile.Id);
                    }
                });
        });
    };
    const onUpdateFiletransactionUpload = async (status) => {
        setLoading(true);
        setShowAlertConfirmation(false);
        const json = await updateFiletransactionUpload(selectedFile.Id, status);
        if (json.httpStatusCode === 200) {
            setShowAlert(true);
            setShowAlertTitle('Success');
            setShowAlertMessage('File updated successfully');
            loadFilesUploaded(customerIdSelected, fromDate, toDate);
            loadFileTransactionDetails(selectedFile.Id);
        } else {
            setShowAlert(true);
            setShowAlertTitle('Error');
            setShowAlertMessage(json.Message);
        }
        setLoading(false);
    };

    const save = async (record, row) => {
        try {
            //event.stopPropagation();
            //const row = await form.validateFields();
            setLoading(true);
            const json = await updateFiletransactionUpload(record.Id, row['Status']);
            if (json != null) {
                if (json.httpStatusCode === 200) {
                    setShowAlert(true);
                    setShowAlertTitle('Success');
                    setShowAlertMessage('File updated successfully');
                    loadFilesUploaded(customerIdSelected, fromDate, toDate);
                    loadFileTransactionDetails(selectedFile.Id);
                    setEditingKey('');
                    form.resetFields();
                    loadFilesUploaded(customerIdSelected, fromDate, toDate);
                } else {
                    setShowAlert(true);
                    setShowAlertTitle('Error');
                    setShowAlertMessage(json.Message);
                }
            }
            setLoading(false);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }

    const onCloseDialog = () => {
        setShowDialog(false);
        setSelectedFile({});
    };

    const onCloseAlertDelete = () => {
        setShowAlertConfirmation(false);
        setFileToDeleteId('');
    };

    const onCloseAlert = () => {
        setShowAlert(false);
    };

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let styleEdit = { margin: 0 };

        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        let node = null;

        if (dataIndex === 'Status') {
            node = (
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a Status"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {['Pending Review', 'In process', 'Completed'].map((status, i) => (
                        <Option
                            key={`FilesReview-options-${status}-${i}`}
                            value={status}>{status}
                        </Option>
                    ))}
                </Select>);
        } else {
            node = <Input disabled readOnly style={{ textAlign: 'center' }} />
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit} rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`
                        }
                    ]}>
                        {node}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    //Search

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = (value, col) => {
        setSearchText(value);
        setSearchedColumn(col);
        setSearchArray(...(searchArray[col] = value));
        setFilteringSomething(true);
    };
    //

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) => (
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (text)
        )
    });

    /*const columns = [
        {
            title: 'Date',
            dataIndex: 'Date',
            editable: false,
            align: 'center',
            sorter: (a, b) => {
                return a.Date.localeCompare(b.Date);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Date')
        },
        {
            title: 'MSB',
            dataIndex: 'MSB',
            editable: false,
            align: 'center',
            sorter: (a, b) => {
                return a.MSB.localeCompare(b.MSB);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('MSB')
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a.Status.localeCompare(b.Status);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'File Name',
            dataIndex: 'FileName',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a.FIleName.localeCompare(b.FileName);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Number of records',
            dataIndex: 'RecordsNumber',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a.RecordsNumber.localeCompare(b.RecordsNumber);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Total Amount',
            dataIndex: 'TotalAmount',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a.TotalAmount.localeCompare(b.TotalAmount);
            },
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={(event) => save(record, event)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={(event) => {event.stopPropagation(); cancel(currentPage)} }>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey !== ''} onClick={(event) => edit(record, event)}>
                                Edit
                            </Typography.Link>
                        </Space>
                    </div>
                );
            }
        }
    ].map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });*/

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    }

    let fileIsSelected = selectedFile.Id != null;
    let uploadedFileStatusButtons = [];
    let transactionDetailsInputFields = [];
    if (fileIsSelected) {
        let status = selectedFile.Status;
        if (['Pending', 'Pending Review'].includes(status)) {
            uploadedFileStatusButtons.push(
                <button key="btnDeleteFIle" className="uk-button uk-button-default uk-button-green" type="button" onClick={onDeleteRowSelected}>
                    Delete File
                </button>
            );
        }
        if (['In process'].includes(status)) {
            transactionDetailsInputFields = ['Comment'];
            // uploadedFileStatusButtons.push(
            //     <button
            //         key="btnSetAsPending"
            //         className="uk-button uk-button-default uk-button-green"
            //         type="button"
            //         onClick={() => onUpdateFiletransactionUpload('Pending Review')}
            //     >
            //         Set Pending Review
            //     </button>
            // );
            // uploadedFileStatusButtons.push(
            //     <button
            //         key="btnSetAsCompleted"
            //         className="uk-button uk-button-default uk-button-green"
            //         type="button"
            //         onClick={() => onUpdateFiletransactionUpload('Completed')}
            //     >
            //         Set Completed
            //     </button>
            // );
            uploadedFileStatusButtons.push(
                <button key="btnSaveComments" className="uk-button uk-button-default uk-button-green" type="button" onClick={onSaveComments}>
                    Save Comments
                </button>
            );
        }
        // if (['Completed', 'Pending', 'Pending Review'].includes(status)) {
        //     uploadedFileStatusButtons.push(
        //         <button
        //             key="btnSetAsInProcess"
        //             className="uk-button uk-button-default uk-button-green"
        //             type="button"
        //             onClick={() => onUpdateFiletransactionUpload('In process')}
        //         >
        //             Set In Process
        //         </button>
        //     );
        // }
    }

    return (
        <div style={{ height: '100%', overflow: 'visible' }}>
            <h1 className="uk-heading-divider" style={style.titleStyle}>
                Files Review
            </h1>
            <div className="uk-form-stacked uk-grid">
                <div className="uk-width-1-3  uk-form-stacked">
                    <label className="uk-form-label">Customer</label>
                    <DynamicSelect
                        id="select-msb-customers"
                        placeholder="Select a MSB..."
                        data={msbCustomerList}
                        objValue={{ value: customerIdSelected }}
                        getValue={onCustomerSelected}
                    />
                </div>
                <div className="uk-width-1-3  uk-form-stacked">
                    <label className="uk-form-label" htmlFor="form-date">
                        From Date
                    </label>
                    <DatePicker
                        useNew
                        className="uk-input"
                        style={style.inputStyle}
                        value={fromDate}
                        onDayChange={(e) => {
                            onDateChange(e);
                        }}
                    />
                </div>
                <div className="uk-width-1-3  uk-form-stacked">
                    <label className="uk-form-label" htmlFor="form-date">
                        To Date
                    </label>
                    <DatePicker
                        useNew
                        className="uk-input"
                        style={style.inputStyle}
                        value={toDate}
                        onDayChange={(e) => {
                            onToDateChange(e);
                        }}
                    />
                </div>
                <div className="uk-width-1-3  uk-form-stacked"></div>
            </div>
            <div className="uk-margin">
                <button key="btnRefresh" className="uk-button uk-button-default uk-button-green" type="button" onClick={onRefresh}>
                    Refresh
                </button>
            </div>
            <h3 className="uk-heading-divider" style={style.subTitleStyle}>
                Uploaded Files
            </h3>

            <div className="uk-width-1-1 uk-form-stacked">
                <NewEditableGrid
                    data={transactionUploadFiles}
                    columnDetails={columns}
                    isEditable={true}
                    editSaveFunction={save}                    
                    useExportButton={true}
                    tableName="FilesReview"
                    useIcons={true} 
                    dynamicActions={[{name: 'View', function: onRowSelected, isIcon: true, icon: <SearchOutlined /> }]} />
            </div>

            {/*<Form form={form}>                
                <div className="uk-overflow-auto">
                    <TheCSVButton board={'FilesReview'} data={transactionUploadFiles} useIcon={true}/>
                </div>
                <Table components={{ body: { cell: EditableCell } }} dataSource={transactionUploadFiles} columns={columns} size="small"
                    pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: itemRender,
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default',
                        defaultPageSize: defaultRowsSize
                    }}
                    onRow={(record) => {
                        if (editingKey === '') {
                            return {
                                onClick: () => onRowSelected(record)
                            }
                        } else {
                            return {
                                onClick: () => {}
                            }
                        }
                    }}
                />
                </Form>*/}
            {fileIsSelected ? (
                <div>
                    <h1 className="uk-heading-divider" style={style.titleStyle}>
                        File Details (Status: {selectedFile.Status}, ID: {selectedFile.Id})
                    </h1>
                    <div className="uk-margin">{uploadedFileStatusButtons}</div>
                    <TableDetails
                        data={fileTransactionDetails}
                        hiddenColumns={['TransactionUploadId', 'DetailId', 'UpdateToken', 'RowUpdated', 'key']}
                        inputFields={transactionDetailsInputFields}
                        updateData={updateData}
                        triggerUpdate={triggerUpdate}
                    />
                </div>
            ) : (
                <div></div>
            )}
            <LoadingBox loading={loading} />
            <AlertBox open={showAlert} title={showAlertTitle} message={showAlertMessage} type="Ok" onClose={onCloseAlert} okClick={onCloseAlert} />
            <AlertBox
                open={showAlertConfirmation}
                title={showAlertConfirmationTitle}
                message={showAlertConfirmationMessage}
                type="Yes/No"
                onClose={onCloseAlertDelete}
                yesClick={onDeleteFile}
                noClick={onCloseAlertDelete}
            />
            <Dialog open={showDialog} onClose={onCloseDialog} fullWidth={true} maxWidth="md">
                <DialogTitle id="show-file-details">
                    <div className="">
                        <h4 className="">
                            File Details<span id="file-details"></span>
                        </h4>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <UploadFileForm file={selectedFile} onClose={onCloseDialog} />
                </DialogContent>
            </Dialog>
        </div>
    );
}
