import React from 'react';
import { useState } from 'react';
import { formatDate, formatToCurrencyWithScale, formatValidInput, formatValidSelect } from '../../../helpers/FormatHelper';
import { searchCustomerByIdNameMin } from '../../../networking/NetworkingCustomers';
import { getTransferHistory } from '../../../networking/NetworkingFunds';
import { getStyle } from '../../../styles/styles';
import useCurrenciesList from '../../maintenance/blotter/hooks/useCurrenciesList';
import DatePicker from '../../shared/DatePicker';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import DynamicSelect from '../../shared/DynamicSelect';
import NewDynamicTable from '../../shared/NewDynamicTable';
import NewEditableGrid from '../../shared/NewEditableGrid';
const style = getStyle();

const HeldFundsBalanceTransferSearchScreen = () => {
    const [currencies] = useCurrenciesList();
    const [transferHistory, setTransferHistory] = useState([]);
    const [validations, setValidations] = useState({
        customerSendingSelectedFilter: true,
        customerReceivingSelectedFilter: true,
        currencyIDFilter: true,
        fromDateFilter: true,
        toDateFilter: true,
    });

    const [customerSendingSelectedFilter, setCustomerSendingSelectedFilter] = useState({ value: 0, name: '' });
    const [customerReceivingSelectedFilter, setCustomerReceivingSelectedFilter] = useState({ value: 0, name: '' });
    const [currencyIDFilter, setCurrencyIDFilter] = useState({ value: '', name: '' });
    const [fromDateFilter, setFromDateFilter] = useState('');
    const [toDateFilter, setToDateFilter] = useState('');
    const [customerListFilter, setCustomerListFilter] = useState([]);
    const [customerListReceivingFilter, setCustomerListReceivingFilter] = useState([]);
    const [columnDetail, setColumnDetail] = useState();

    const getTransferHistoryClick = async () => {
        const json = await getTransferHistory(checkZero(customerSendingSelectedFilter.value), checkZero(customerReceivingSelectedFilter.value), checkEmpty(currencyIDFilter.value), checkEmpty(fromDateFilter), checkEmpty(toDateFilter));
        if (json != null && json.result != null) {
            setTransferHistory(json.result);
            if (json.columnDetail != null) {
                const _columnDetail = JSON.parse(json.columnDetail);
                setColumnDetail(_columnDetail);
            }
        } else {
            setTransferHistory([]);
        }
    };

    const clearFilterClick = () => {
        setCustomerSendingSelectedFilter({ value: 0, name: '' });
        setCustomerReceivingSelectedFilter({ value: 0, name: '' });
        setCurrencyIDFilter({ value: '', name: '' });
        setFromDateFilter('');
        setToDateFilter('');
        setCustomerListFilter([]);
        setCustomerListReceivingFilter([]);
        setTransferHistory([]);
        setValidations({
            customerSendingSelectedFilter: true,
            customerReceivingSelectedFilter: true,
            currencyIDFilter: true,
            fromDateFilter: true,
            toDateFilter: true
        });
    };

    const searchCustomerFilter = (query, dropdown) => {
        if (query === '') {
            if (dropdown === 'Sending') {
                setCustomerListFilter([]);
            } else {
                setCustomerListReceivingFilter([]);
            }
        } else {
            searchCustomerByIdNameMin(query).then(
                (response) => {
                    if (response.customers !== undefined) {
                        for (var i = 0; i < response.customers.length; i++) {
                            response.customers[i].value = response.customers[i].CustomerID;
                            response.customers[i].name = response.customers[i].CustomerIDName;
                        }
                        if (dropdown === 'Sending') {
                            setCustomerListFilter(response.customers);
                        } else {
                            setCustomerListReceivingFilter(response.customers);
                        }
                    }
                }
            )
        }
    };

    const handleUpdateSendingCustomerFilter = (value) => {
        if (value.value !== undefined) {
            setCustomerSendingSelectedFilter(value.value !== 'select' ? value : 0);
            setValidations({ ...validations, customerSendingSelectedFilter: true });
            setCustomerListFilter([]);
        }
    };

    const handleUpdateReceivingCustomerFilter = (value) => {
        if (value.value !== undefined) {
            setCustomerReceivingSelectedFilter(value.value !== 'select' ? value : 0);
            setValidations({ ...validations, customerReceivingSelectedFilter: true });
            setCustomerListReceivingFilter([]);
        }
    };

    const handleGetValueCurrencyFilter = (value) => {
        if (value != null && value !== 'select' && value.value !== undefined) {
            setCurrencyIDFilter(value);
            setValidations({ ...validations, currencyIDFilter: true });
        }
    };

    const handleUpdateFromDateFilter = (date) => {
        setFromDateFilter(formatDate(date));
        setValidations({ ...validations, fromDateFilter: true });
    };

    const handleUpdateToDateFilter = (date) => {
        setToDateFilter(formatDate(date));
        setValidations({ ...validations, toDateFilter: true });
    };

    const checkZero = (value) => value !== 0 ? value : null;

    const checkEmpty = (value) => value !== '' ? value : null;

    return (
        <div className='uk-grid'>
            <div className="uk-width-1-1 uk-margin">
                <form className="uk-form-horizontal">
                    <div className="uk-grid">
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Sending Customer (*)</label>
                            <div className="" style={{ paddingBottom: "5px" }}>
                                <DynamicSearchSelect
                                    className="uk-input"
                                    id="select-sending-customer-filter"
                                    placeholder="Select a Customer..."
                                    searchFunction={(query) => searchCustomerFilter(query, 'Sending')}
                                    getValue={(event) => handleUpdateSendingCustomerFilter(event)}
                                    data={customerListFilter}
                                    objValue={{ value: customerSendingSelectedFilter.value, name: customerSendingSelectedFilter.name }}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Receiving Customer (*)</label>
                            <div className="" style={{ paddingBottom: "5px" }}>
                                <DynamicSearchSelect
                                    className="uk-input"
                                    id="select-receiving-customer-filter"
                                    placeholder="Select a Customer..."
                                    searchFunction={(query) => searchCustomerFilter(query, 'Receiving')}
                                    getValue={(event) => handleUpdateReceivingCustomerFilter(event)}
                                    data={customerListReceivingFilter}
                                    objValue={{ value: customerReceivingSelectedFilter.value, name: customerReceivingSelectedFilter.name }}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Currency (*)</label>
                            <div className="" style={style.inputStyle}>
                                <DynamicSelect
                                    className="uk-select"
                                    objValue={{ value: currencyIDFilter.value, name: currencyIDFilter.name }}
                                    getValue={handleGetValueCurrencyFilter}
                                    listHasPlaceholder={false}
                                    placeholder={"Select a Currency..."}
                                    data={currencies}
                                    id="form-commission-currencies-filter"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="uk-grid grid-margin-15">
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>From Date</label>
                            <div className="" style={{ paddingBottom: "5px" }}>
                                <DatePicker
                                    useNew
                                    value={fromDateFilter}
                                    onDayChange={handleUpdateFromDateFilter}
                                    className='uk-input'
                                    id="from-date-filter"
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="uk-width-1-4 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>To Date</label>
                            <div className="" style={{ paddingBottom: "5px" }}>
                                <DatePicker
                                    useNew
                                    value={toDateFilter}
                                    onDayChange={handleUpdateToDateFilter}
                                    className='uk-input'
                                    id="from-date-filter"
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="uk-width-1-1">
                <button className="uk-button uk-button-green uk-margin-right" onClick={getTransferHistoryClick}>Search</button>
                <button className="uk-button uk-button-green uk-margin-right" onClick={clearFilterClick}>Clear Filter</button>
            </div>
            <div className="uk-width-1-1">
                <NewEditableGrid
                    // hiddenHeaders={['Key', 'Action']}
                    data={transferHistory}
                    columnDetails={columnDetail}
                    useExportButton={true}
                    // formats={[{ header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Currency' }]}
                    tableName="transfers" />
            </div>
        </div>
    );
}

export default HeldFundsBalanceTransferSearchScreen;
